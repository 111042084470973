import { createApi } from '../common/API'
import useAsync from '../../hooks/async'
import { useEffect } from 'react';

export const fetchCategories = createApi('/pack/queryForCat');

export const fetchPacks = createApi('/pack/query');

export const fetchBanners = createApi('/banner/query');

export const useBaners = () => {
  const data = useAsync(fetchBanners, true)
  return {...data}
}

export const usePacks = () => {
  const data = useAsync(fetchPacks, true)
  return {...data}
}

export const useCategories = (catId) => {
  const {execute, ...data} = useAsync(fetchCategories, false)
  useEffect(() => {
    execute({catId})
  }, [catId])
  return {...data}
}