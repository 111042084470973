import React from 'react';
// import './App.css';
import { Provider } from 'react-redux';
import Routes from './app/routes'
import store from './app/store';
import StyledThemeProvider from './components/StyledThemeProvider'
// import { SnackbarProvider } from 'notistack';
import Notifier from './components/Notifier'
import PackPlayer from './features/common/PackPlayer'


const App = () => {
  return (
    <Provider store={store}>
      <StyledThemeProvider scope="app">
        {/* <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={3000}> */}
          <Notifier />
          <Routes />
          <PackPlayer />
        {/* </SnackbarProvider> */}
      </StyledThemeProvider>
    </Provider>
  )
};

export default App;
