import { useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { play as playAction, 
  updateProgress as updateProgressAction, 
  loaded as loadedAction, 
  closePlayer as closePlayerAction,
  togglePlaying as togglePlayingAction,
  pause as pauseAction,
} from './packPlayerSlice'
import usePlayer from './usePlayer'

const usePackPlayer = () => {
  const dispatch = useDispatch()
  const packPlayer = useSelector(state => state.packPlayer)
  const player = usePlayer()
  const isPack = player.type === 'pack'

  const show = useMemo(() => {
    return isPack && packPlayer.show
  }, [packPlayer.show, isPack])

  const playing = useMemo(() => {
    return isPack && packPlayer.playing
  }, [packPlayer.playing, isPack])

  const isPlaying = useCallback((pack) => {
    return isPack && pack && packPlayer.pack && packPlayer.pack.id === pack.id && packPlayer.playing
  }, [packPlayer.pack, packPlayer.playing, isPack])

  const playPack = useCallback((pack) => {
    //console.log('playPack', pack)
    dispatch(playAction(pack || packPlayer.pack))
    player.playType('pack')
  }, [player, packPlayer.pack, dispatch])

  const setProgress = useCallback((state) => {
    dispatch(updateProgressAction(state))
  }, [dispatch])

  const onLoaded = useCallback((value) => {
    dispatch(loadedAction(value))
  }, [dispatch])

  const closePlayer = useCallback(() => {
    dispatch(closePlayerAction())
  }, [dispatch])

  const togglePlaying = useCallback(() => {
    dispatch(togglePlayingAction())
  }, [dispatch])

  const pause = useCallback(() => {
    dispatch(pauseAction())
  }, [dispatch])

  useEffect(() => {
    if (player.type != 'pack') {
      dispatch(closePlayerAction())
    }
  }, [player.type])

  return {
    pack: packPlayer.pack,
    playing,
    show,
    progress: packPlayer.progress,
    duration: packPlayer.duration,
    url: packPlayer.url,
    volume: player.volume,
    changeVolume: player.changeVolume,
    isPlaying,
    playPack,
    setProgress,
    closePlayer,
    togglePlaying,
    onLoaded,
    pause
  }
}

export default usePackPlayer