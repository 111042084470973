import { createApi } from '../common/API'
import useAsync from '../../hooks/async'
import { useCallback } from 'react'

export const fetchLoops = createApi('/loop/query');


export const useLoops = ({packId, userId}) => {
  const call = useCallback(() => fetchLoops({packId, userId}), [packId, userId])
  const data = useAsync(call, true)
  return {...data}
}

export const addToMyLoop = createApi('/loop/addToMyLoop', {method: 'POST'});

export const deleteLoop = createApi('/loop/delete', {method: 'POST'});

export const fetchPack = createApi('/pack/query');

export const fetchDownload = createApi('/privilege/download', {method: 'POST'});