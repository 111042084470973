import React, { useCallback, useEffect, useState } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Password = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  const styles = { color: "#CCC"}

  return <OutlinedInput {...props} type={showPassword ? 'text' : 'password'} endAdornment={
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        edge="end"
      >
        {showPassword ? <VisibilityOff style={styles} /> : <Visibility style={styles} />}
      </IconButton>
    </InputAdornment>
  } />
}

export default Password