import React from 'react'
import { useNavigate } from "@reach/router"

import { useLocalObservable } from "mobx-react-lite"
import userStore from '../common/userStore'

import { createApi } from '../common/API'

const userRegister = createApi('/user/register', {method: 'POST', expandData: true, notify: true, noThrow: false});


const useSignup = () => {
  const navigate = useNavigate()

  const signupForm = useLocalObservable(() => ({
    email: '',
    password: '',
    posting: false,
    immediately: false,
    errors: {email: null, password: null},
    handleEmail(e) {
      this.email = e.target.value
      this.immediately && this.validEmail()
    },
    handlePassword(e) {
      this.password = e.target.value
      this.immediately && this.validPassword()
    },
    validEmail() {
      if (this.email.length < 1) {
        this.errors.email = 'Email is required'
        return false
      }
      const pattern = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/g
      if (!(pattern.test(this.email.toString()))) {
        this.errors.email = 'Email is invalid'
        return false
      }
      this.errors.email = null
      return true
    },
    validPassword() {
      if (this.password.length < 1) {
        this.errors.password = 'Password is required'
        return false
      }
      if (this.password.length < 8) {
        this.errors.password = 'At least 8 characters in length'
        return false
      }
      this.errors.password = null
      return true
    },
    handleKeyup(e) {
      if(e.keyCode === 13) {
        this.handleSubmit()
      }
    },
    async handleSubmit() {
      try {
        this.posting = true
        this.immediately = true
        const ck = [this.validEmail(), this.validPassword()]
        if (ck.some(c => !c)) {
          return;
        }
        await userRegister({
          email: this.email,
          password: this.password
        })
        userStore.reload()
        navigate('/', {replace: true})
      } catch (e) {
        console.log('reg fail', e)
        this.errors.email = 'Email already existed'
      } finally {
        this.posting = false
      }
    },
  }))

  return signupForm
}

export default useSignup