import React from 'react'
import Typography from '@mui/material/Typography';
import styles from './HomePacks.module.scss'
import clsx from 'clsx';

import PackCard from '../common/PackPlayerCard'
import { useCategories, usePacks } from './HomeAPI'

import PackList from '../common/PackList'
import useWindowSize from '../../hooks/window-size';


const useHomeCategories = (catId) => {
  const { value, status } = useCategories(catId)
  return {
    loading: status !== 'success',
    categories: status === 'success' ? value : []
  }
}

const useHomePacks = () => {
  const { value, status } = usePacks()
  return {
    loading: status !== 'success',
    packs: status === 'success' ? value : []
  }
}

const HomePacks = ({className, description, catId}) => {
  const {width} = useWindowSize()
  const {categories, loading} = useHomeCategories(catId)
  // const {packs, loading} = useHomePacks()

  if (loading) {
    // 暂时如此
    return <div className={clsx(styles.root, className)}></div>
  }
  

  return <div className={clsx(styles.root, className)}>
  {categories.map((category, index) => <PackList mode={width < 768 ? 'inline' : 'full'} key={index} title={` Browse All ${Object.keys(category)[0]} Packs`} packs={Object.values(category)[0]}/>)}
  </div>

  // return <div className={clsx(styles.root, className)}>
  //   <Typography variant="h2" className={styles.title}>
  //   Browse All Guitar Packs
  //   </Typography>
  //   <div className={styles.desc}>
  //     {description}
  //   </div>
  //   <div className={styles.list}>
  //     {packs.map((pack) => {
  //       return <div  key={pack.id} className={styles.cell}><PackCard className={styles.card} pack={pack} /></div>
  //     })}
  //   </div>
  // </div>
}

export default HomePacks