import { createApi } from './API'
// import useAsync from '../../hooks/async'


export const doUserLogin = createApi('/user/login', {method: 'POST', notify: true, noThrow: false});
export const doUserLogout = createApi('/user/logout', {method: 'POST'});
export const fetchUserInfo = createApi('/user/getUserInfo', {method: 'POST', notify: false});
export const doUserRegister = createApi('/user/register', {method: 'POST'});
export const fetchAccount = createApi('/trade/queryAccount', {method: 'POST'});
export const authByGoogle = createApi('/auth/verifyGoogle', {method: 'POST', expandData: true, notify: true, noThrow: false});
export const authByFacebook = createApi('/auth/verifyFacebook', {method: 'POST', expandData: true, notify: true, noThrow: false});