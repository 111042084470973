import React from 'react'
import { observer } from "mobx-react-lite"
import Welcome from './welcome/Welcome'
import Home from './home/Home'

import useUser from './common/useUser'


const Index = observer(props => {
  const {user} = useUser();
  return user.auth === 1 ? <Home {...props} /> : <Welcome {...props} />;
})

export default Index;
