import React, {useEffect} from 'react'
import { observer } from "mobx-react-lite"
import styles from './Layout.module.scss'
import { useLocation } from "@reach/router"

import AuthHeader from './AuthHeader';
// import NoAuthHeader from './NoAuthHeader';
import userStore from '../common/userStore';
import clsx from 'clsx';
import useTitle from '../../hooks/title';
import usePlayer from '../common/usePlayer';
import SignupModal from '../auth/SignupModal';

import Header from '../header/Header';

const Layout = observer(props => {
  const location = useLocation()
  const player = usePlayer()

  useTitle(props.title)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    if (userStore.isAnonymous) {
      player.playType(null)
    }
  }, [userStore.isAnonymous])

  return (
    <div className={clsx(styles.root)}>
      {userStore.isAnonymous ? <Header normal /> : <AuthHeader />}
      <div className={clsx(styles.body, props.className, userStore.isAnonymous && 'anonymous')}>
      {props.children}
      </div>
      <div className={styles.footerLine}></div>
      {userStore.isAnonymous ? <SignupModal /> : null}
    </div>
  )
});

export default Layout