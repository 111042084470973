import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css'
import './index.css';
import 'overlayscrollbars/css/OverlayScrollbars.css'
import App from './App'
// import * as serviceWorker from './serviceWorker';

import { supportsWebp } from './utils'

supportsWebp(window).then(res => {
  if (res) {
    window.document.body.className = 'webp'
  }
}).catch(err => {
  console.log(err)
})

// const importBuildTarget = () => {
//   if (window.location.hostname.indexOf('.cn') > -1) {
//     return import('./AppZh')
//   }
//   return import('./App')
// }

// importBuildTarget().then(({ default: App }) => {
//   ReactDOM.render(
//       <React.StrictMode>
//         <App />
//       </React.StrictMode>,
//       document.getElementById('root')
//   );
// })

ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
