import React, { useCallback, useLayoutEffect, useRef } from 'react'
import clsx from 'clsx'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import styles from './HomeCarousel.module.scss'
// import { Carousel } from 'react-responsive-carousel'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'


import { useBaners } from './HomeAPI'
import HomeBanner from './HomeBanner'

import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'


const useItems = () => {
  const { status, value } = useBaners()
  
  return {
    items: status === 'success' ? (value || []) : []
  }
}

const HomeCarousel = () => {
  const { items } = useItems()
  // const insRef = useRef(null)
  
  // const renderPreButton = useCallback((handle) => {
  //   return <div className={clsx(styles.button, styles.buttonPre)} onClick={handle}><ChevronLeftIcon /></div>
  // }, [])

  // const renderNextButton = useCallback((handle) => {
  //   return <div className={clsx(styles.button, styles.buttonNext)} onClick={handle}><ChevronRightIcon /></div>
  // }, [])

  // useLayoutEffect(() => {
  //   console.log(insRef.current)
  //   if (insRef.current) {
  //     setTimeout(() => insRef.current.listRef.style.transform = 'translate3d(-100%, 0px, 0px)', 300)
  //   }
  // }, [insRef.current])

  if (items.length == 0) {
    return <div className={styles['wrap']}></div>
  }

  return <div className={styles['wrap']}>
    {/* <Carousel className={styles.root}
      ref={insRef}
      // autoPlay={true}
      // onChange={(index, item) => {
      //   // let dom = document.querySelector('.slider.animated');
      //   // dom.style.transitionDuration = '0ms'
      //   // console.log('change', index)
      //   // if (index === 2) {
      //   //   setTimeout(() => {
      //   //     let dom = document.querySelector('.slider.animated');
      //   //     dom.style.transitionDuration = '0ms'
      //   //     dom.style.transform = 'translate3d(0px, 0px, 0px)'
      //   //     ///dom.style.transitionDuration = '350ms'
      //   //     setTimeout(() => dom.style.transitionDuration = '350ms', 0)
      //   //   }, 0)
      //   // }
      // }}
      dynamicHeight={true}
      showThumbs={false} 
      infiniteLoop={true} 
      showStatus={false}
      interval={3000}
      renderArrowPrev={renderPreButton} 
      renderArrowNext={renderNextButton}>
        {items.map(item => <div key={item.id} className={styles.page}>
            <HomeBanner item={item} />
        </div>
        )}
    </Carousel> */}
    <Swiper
        className={styles.root}
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        loopAdditionalSlides={2}
        navigation={{ nextEl: '.' + styles.buttonNext, prevEl: '.' + styles.buttonPre }}
        loop
        autoplay={{
          "delay": 3000,
          "disableOnInteraction": false
        }}
        pagination={{ clickable: true }}
      >
        {items.map(item => <SwiperSlide key={item.id} className={styles.page}>
            <HomeBanner item={item} />
        </SwiperSlide>)}
    <div className={styles['hidden-left']}></div>
        <div className={clsx(styles.button, styles.buttonPre)}><ChevronLeftIcon /></div>
        <div className={clsx(styles.button, styles.buttonNext)}><ChevronRightIcon /></div>
      </Swiper>
  </div>
}

export default HomeCarousel
