import copy from 'copy-to-clipboard'


function scroll(currentY, targetY) {
  // 计算需要移动的距离
  let needScrollTop = targetY - currentY
  let _currentY = currentY
  setTimeout(() => {
    // 一次调用滑动帧数，每次调用会不一样
    const dist = Math.ceil(needScrollTop / 10)
    _currentY += dist
    window.scrollTo(_currentY, currentY)
    // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
    if (needScrollTop > 10 || needScrollTop < -10) {
      scroll(_currentY, targetY)
    } else {
      window.scrollTo(_currentY, targetY)
    }
  }, 5)

}


function shareUrl(url) {
  window.open(url, '_blank', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
  return false;
}


function shareOnFacebook(url, title){
  if (!title) {
    return shareUrl(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&t=${encodeURIComponent('Make custom loops today on Session Loops')}`)
  }
  return shareUrl(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&t=${encodeURIComponent(title)}`)
}


function shareOnTwitter(url, title){
  if (!title) {
    return shareUrl(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&via=SessionLoops&text=${encodeURIComponent('Make custom loops today on Session Loops')}`)
  }
  return shareUrl(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&via=SessionLoops&text=${encodeURIComponent(title)}`)
}


function shareOnReddit(url, title){
  if (!title) {
    return shareUrl(`http://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent('Make custom loops today on Session Loops')}`)
  }
  return shareUrl(`http://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`)
}

async function copyToClipboard(text) {
  return copy(text)
}

function getRedirectUrl() {
  if (window.location.search) {
    const qs = new URLSearchParams(window.location.search)
    return qs.get('rd')
  }
  return null
}
const supportsWebp = ({ createImageBitmap, Image }) => {
  if (!createImageBitmap || !Image) return Promise.resolve(false);

  return new Promise(resolve => {
      const image = new Image();
      image.onload = () => {
          createImageBitmap(image)
              .then(() => {
                  resolve(true);
              })
              .catch(() => {
                  resolve(false);
              });
      };
      image.onerror = () => {
          resolve(false);
      };
      image.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
  });
};

export { scroll, shareOnFacebook, shareOnTwitter, shareOnReddit, copyToClipboard, getRedirectUrl, supportsWebp}