import axios from 'axios'
import qs from 'qs';

// import { addNotify as addNotifyAction } from './notifySlice'
import { addError } from '../../components/Notifier'

let logout = () => window.location.reload()

axios.defaults.baseURL = '/api'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

class ApiError extends Error {
  constructor(code, message) {
    super(message)
    this.code = code;
  }
}

let authToken = '';
const authTokenKey = 'authToken'

const getToken = () => {
  if (authToken === '') {
    authToken = localStorage.getItem(authTokenKey)
  }
  return authToken;
}

const setToken = (token) => {
  if (token && typeof token === 'string') {
    authToken = token;
    localStorage.setItem(authTokenKey, authToken)
  }
  throw new Error('Invalid Auth Token')
}

const cleanToken = () => {
  authToken = ''
  localStorage.removeItem(authTokenKey)
}

const createApi = (url, opts = {}) => {
  const defaultOpts = {method: 'GET', auth: false, notify: true, noThrow: true, expandData: true}
  opts = { ...defaultOpts, ...opts}
  return async (params) => {
    const reqCfg = {
      url,
      method: opts.method.toLowerCase()
    }
    // if (opts.auth) {
    //   reqCfg['headers'] = {'Authorization': getToken()}
    // }
    if (reqCfg.method === 'get' ) {
      reqCfg['params'] = params || {}
    } else {
      reqCfg['data'] = qs.stringify(params || {})
    }
    try {
      const response = await axios(reqCfg)

      if (response.data.code === 0) {
        return opts.expandData ? response.data.data : response.data;
      }
      // if (!opts.expandData) {
      //   return response.data
      // }
      // {"msg":"登录已失效，请重新登录！","state":"403"}
      throw new ApiError(response.data.code || response.data.state, response.data.message || response.data.msg);
    } catch(error) {
      let msg = {code: -1, message: 'System busy'}
      if (error instanceof ApiError) {
        msg.code = error.code;
        msg.message = error.message;
      }
      else {
        if (error.message)
          msg.message = error.message;
        // console.log(msg, 3)
      }
      //console.log(msg)
      if (msg.code === 403 || msg.code === "403") {
        logout()
        return;
      }
      if (opts.notify) {
        // console.log(msg)
        //addError(msg.message)
      }
      if (!opts.noThrow) {
        if (error instanceof ApiError) {
          throw error;
        }
        throw new ApiError(msg.code, msg.message);
      }
      if (!opts.expandData) {
        return {code: msg.code, message: msg.message}
      }
    }
  }
}

export { createApi, getToken, setToken,  cleanToken}