import {
  createSlice,
} from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    auth: 0, // 0 未知, 1 登录, 2 未登录
    loading: false,
    id: 0,
    username: null,
    email: null,
  },
  reducers: {
    setAuth: (state, action) => {
      const auth = action.payload
      state.auth = auth
      if (auth === 2 || auth === 1) {
        state.id = 0
        state.username = null
        state.email = null
      }
    },
    setUser: (state, action) => {
      state.id = action.payload.id
      state.username = action.payload.username
      state.email = action.payload.email
      if (action.payload.id)
        state.auth = 1
      else
        state.auth = 2
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export const { setAuth, setUser, setLoading } = userSlice.actions;

export default userSlice.reducer
