import React from 'react'
import { observer } from "mobx-react-lite"
import Button from '@mui/material/Button';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import userStore from '../common/userStore';


// login signup
const FacebookButton = observer(({className, type}) => {
  // const text = type == 'login' ? 'Login up with Facebook' : 'Sign up with Facebook'

  const responseFacebook = async(response) => {
    console.log(response);
    if (response.error) {
      return;
    }
    const rs = await userStore.loginByFacebook(response)
    // if (rs) {
    //   console.log('facebook redirect to home')
    //   window.location.replace('/')
    // }
  }

  return  <FacebookLogin
    appId="1319028228564262"
    fields="name,email,picture"
    callback={responseFacebook}
    isMobile={false}
    disableMobileRedirect={true}
    render={(renderProps) => {
      // console.log('facebook', renderProps)
      return <Button
        startIcon={<FacebookOutlinedIcon viewBox="2 2 20 20" width="24px" />} 
        variant="contained" 
        className={className} 
        disableElevation 
        onClick={renderProps.onClick} 
        disabled={renderProps.isDisabled || !renderProps.isSdkLoaded || renderProps.isProcessing}>Facebook</Button>
    }} />
})

export default FacebookButton