import { useCallback } from 'react'

const filename = (url, name) => {
  if (name) {
    const idx = url.lastIndexOf('.')
    return name.replace(/[ /\\?%*:|"<>.]/g, '_') + url.substring(idx, url.length);
  }
  const idx = url.lastIndexOf('/')
  return url.substring(idx, url.length);
}

const download = (url, name) => {
  if (!url) {
    return;
  }
  if (!window.fetch) {
    window.open(url, '_blank')
    return;
  }
  fetch(url).then((res) => {
    res.blob().then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = filename(url, name);
      a.click();
      window.URL.revokeObjectURL(blobUrl);
    });
  });
}

const useDownload = (url, name) => {
  const onDownload = useCallback(() => {
    download(url, name)
  }, [url, name]);

  return {onDownload};
}

export {download}

export default useDownload