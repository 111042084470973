import React, { useEffect } from 'react'
import { observer, useLocalObservable } from "mobx-react-lite"
import clsx from 'clsx';
import { toJS } from 'mobx'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Button from '@mui/material/Button'

import PackCard from './PackPlayerCard'

import classes from './PackList.module.scss'


const useViewModel = (mode) => {

  const vm = useLocalObservable((mode) => ({
    listDom: null,
    scrollWidth: 0,
    scrollLeft: 0,
    clientWidth: 0,
    timeoutId: 0,
    mode: null,
    setListDom(dom) {
      this.listDom = dom;
      if (dom) {
        setInterval(this.resetSize, 300);
      }
      else {
        clearInterval(this.timeoutId)
      }
    },
    setMode(mode) {
      this.mode = mode
    },
    resetSize() {
      if (!this.listDom) {
        return;
      }
      this.scrollWidth =  this.listDom.scrollWidth
      this.scrollLeft =  this.listDom.scrollLeft
      this.clientWidth =  this.listDom.clientWidth
      //console.log('reset ', this.scrollWidth, this.scrollLeft, this.clientWidth)
    },
    nextView() {
      this.listDom.scrollTo(this.listDom.clientWidth + this.listDom.scrollLeft, 0)
    },
    preView() {
      this.listDom.scrollTo(this.listDom.scrollLeft - this.listDom.clientWidth, 0)
    },
    get isPreEnabled() {
      if (this.listDom == null) {
        return true;
      }
      return this.scrollLeft == 0;
    },
    get isNextEnabled() {
      if (this.listDom == null) {
        return true;
      }
      return this.clientWidth + this.scrollLeft == this.scrollWidth;
    }
  }))

  useEffect(() => {
    vm.setMode(mode)
  }, [vm, mode])

  return vm
}

const PackList = observer(({className, title, packs, mode /* inline, full */}) => {
  const vm = useViewModel(mode)

  return <div className={clsx(classes.root, className, classes[mode || 'full'])}>
    <div className={classes.head}>
      <h2>{title}</h2>
      <div className={classes.navBtns}>
        <Button size="small" aria-label="pre" variant="outlined" className={classes.navBtn} onClick={vm.preView} disabled={vm.isPreEnabled}>
          <ChevronLeftIcon />
        </Button>
        <Button size="small" aria-label="next" variant="outlined" className={classes.navBtn} onClick={vm.nextView} disabled={vm.isNextEnabled}>
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
    <div className={classes.body} ref={vm.setListDom}>
      <div className={classes.items}>
        {packs.map((pack) => {
          return <div  className={classes.item} key={pack.id}><PackCard  className={classes.card} pack={toJS(pack)} /></div>
        })}
      </div>
    </div>
  </div>
})

export default PackList