
import React from 'react'

import Layout from '../layout/Layout'
import HomeCarousel from './HomeCarousel'

import HomePacks from './HomePacks'
import styles from './Home.module.css'
import Footer from '../footer/Footer'
import CookieAgree from '../welcome/CookieAgree'


const Home = () => {

  return <Layout>
    <div className={styles.root}>
      <div className={styles.slogan}></div>
      <HomeCarousel />
      <HomePacks/>
      {/* <div style={{height: 100, margin: '0 auto'}}></div> */}
    </div>
    <Footer />
    <CookieAgree />
  </Layout>
}

export default Home