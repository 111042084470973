import { useEffect } from 'react'


function useTitle(title) {
  const defaultTitle = 'Session Loops'
  useEffect(() => {
    if (!title) {
      document.title = defaultTitle
      return
    }
    document.title = `${title} | ${defaultTitle}`
    return () => {
      document.title = defaultTitle
    }
  }, [title])
}

export default useTitle