import { makeAutoObservable, runInAction } from "mobx"
import { fetchUserInfo, doUserLogin, doUserLogout, fetchAccount, authByGoogle, authByFacebook } from './UserAPI'
import {addSuccess, addError} from '../../components/Notifier'
import {getRedirectUrl} from "../../utils";

class UserModel {

  loading = false

  posting = false

  auth = 0 // 0 未知, 1 登录, 2 未登录, 3 超时

  id = 0

  user = {}

  credit = 0

  loginBy = null

  needMerge = false

  accessToken = null

  openSignup = false

  constructor() {
    makeAutoObservable(this)
  }

  get isAnonymous() {
    return this.auth > 1
  }

  get isLogin() {
    return this.auth == 1
  }

  get ready() {
    return !this.loading && this.auth > 0
  }

  get detail() {
    const d = {
      id: this.id,
      auth: this.auth,
      name: this.user.name, // display name
      username: this.user.username, // user id
      socialName: this.user.socialName, // username
      email: this.user.email,
      loading: this.loading,
      posting: this.posting,
      credit: this.credit
    }
    // console.log(d)
    return d
  }

  setUser = (user) => {
    this.auth = 1
    this.id = user.id
    this.user = user
    this.needMerge = false
    this.loginBy = null
    this.accessToken = null
  }

  login = async({username, password}) => {
    try {
      this.posting = true
      const rs = await doUserLogin({loginName: username, password, rememberMe: 1})
      // await this.load()
      const rd = getRedirectUrl()
      if (rd) {
        window.location.replace(rd)
        return
      }
      window.location.replace('/')
    } finally {
      runInAction(() => this.posting = false)
    }
  }

  logout = async() => {
    // console.log('logout', timeout)
    // if (timeout) {
    //   this.auth = 3
    //   return
    // }
    try {
      this.posting = true
      await doUserLogout()
      // addSuccess('已登出!')
      // runInAction(() => {
      //   this.auth = 2
      //   this.user = {}
      //   this.credit = 0
      // })
      window.location.replace('/')
    } finally {
      runInAction(() => this.posting = false)
    }
  }

  load = async() => {
    try {
      this.loading = true
      const user = await fetchUserInfo();
      // console.log('load user', user)
      if (!user) {
        runInAction(() => this.auth = 2)
      }
      else {
        this.setUser(user)
        this.loadCredit()
      }
    } finally {
      runInAction(() => this.loading = false)
    }
  }

  reload = async() => {
    this.auth = 0
    return await this.load()
  }

  loadCredit = async() => {
    const rs = await fetchAccount();
    if (rs && rs.userId) {
      runInAction(() => {
        this.credit = rs.credit || 0
      })
    } else {
      runInAction(() => {
        this.credit = 0
      })
    }
  }

  hasCredit = async() => {
    await this.loadCredit()
    return this.credit > 0
  }

  init = async() => {
    if (this.loading || this.auth > 0) {
      return;
    }
    await this.load()
  }

  setMergeBy = (type, token) => {
    this.needMerge = true
    this.loginBy = type
    this.accessToken = token
  }

  cancelMerge = () => {
    this.needMerge = false
    this.loginBy = null
    this.accessToken = null
  }

  mergeByGoogle = async(password) => {
    await authByGoogle({
      idTokenString: this.accessToken,
      password,
      rememberMe: 1
    })
    // await this.load()
    window.location.replace('/')
  }

  loginByGoogle = async(googleResp) => {
    try {
      const idToken = googleResp.credential || googleResp.id_token || googleResp.access_token || googleResp.code
      // this.setMergeBy('google', idToken)
      // return
      if (!idToken) {
        return false;
      }
      const rs = await authByGoogle({
        idTokenString: idToken,
        rememberMe: 1
      })
      console.log('resp', rs)
      if (rs.needMerge == 1) {
        this.setMergeBy('google', idToken)
        return false
      }
      // if (this.openSignup) {
      //   await this.load()
      //   return;
      // }
      window.location.replace('/')
      return true
    } catch (e) {
      addError(e.message)
      return false;
    }
  }

  mergeByFacebook = async(password) => {
    await authByFacebook({
      token: this.accessToken,
      password,
      rememberMe: 1
    })
    // await this.load()
    window.location.replace('/')
  }

  loginByFacebook = async(facebookResp) => {
    try {
      // {
      //   status: 'connected',
      //   authResponse: {
      //     accessToken: '...',
      //     expiresIn:'...',
      //     reauthorize_required_in:'...',
      //     data_access_expiration_time: '...',
      //     signedRequest:'...',
      //     userID:'...'
      //   }
      // }
      const accessToken = facebookResp.accessToken
      console.log(accessToken)
      if (!accessToken) {
        return false;
      }
      const rs = await authByFacebook({
        token: accessToken,
        rememberMe: 1
      })
      console.log('resp', rs)
      if (rs.needMerge == 1) {
        this.setMergeBy('facebook', accessToken)
        return false;
      }
      // if (this.openSignup) {
      //   await this.load()
      //   return;
      // }
      window.location.replace('/')
      return true
    } catch (e) {
      addError(e.message)
      return false;
    }
  }

  setOpenSignup = (open) => {
    this.openSignup = open
  }
}

const userStore = new UserModel()

export default userStore
