import React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';



const appTheme = createTheme({
  palette: {
    primary: {
      main: '#FF6500',
      contrastText: '#FFFFFF'
    }
  },
  typography: {
    h1: {
      // fontFamily: 'Futura Medium',
      fontSize: 40
    },
    h2: {
      // fontFamily: 'Futura Medium',
      fontSize: 30
    },
    h3: {
      // fontFamily: 'Futura Medium',
      fontSize: 20
    },
    h4: {
      // fontFamily: 'Futura Medium',
      fontSize: 18
    },
    body1: {
      fontFamily: 'Futura Medium',
    },
    body2: {
      fontFamily: 'Futura Medium',
      fontSize: 16,
      color: '#454545'
    }
  }
});



const editorTheme = createTheme({
  palette: {
    primary: {
      main: '#FF6500',
      contrastText: '#FFFFFF'
    },
  },
  typography: { 
    body1: {
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    },
    body2: {
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    }
  }
});

const StyledThemeProvider = ({ scope, children }) => {
  const theme = scope == 'editor' ? editorTheme : appTheme
  return <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
}

export default StyledThemeProvider