import React from 'react'
import { observer, useLocalObservable } from "mobx-react-lite"
import Modal from '@mui/material/Modal';
import classes from './video.module.scss'

const Video = observer(({open, onClose}) => {
  
  return <Modal open={open} onClose={onClose} 
      onClick={(e) => e.stopPropagation()} classes={{root: classes.root}}>
      <iframe className={classes.video} 
        src="https://www.youtube.com/embed/PNSkGdfZrRs?autoplay=1" 
        title="Session Loops Tutorial" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen></iframe>
  </Modal>
})

const useVideo = () => {
  return useLocalObservable(() => ({
    open: false,
    show() {
      this.open = true
    },
    hide() {
      this.open = false
    }
  }))
}

export default Video;
export { useVideo };