import { makeAutoObservable, runInAction } from "mobx"
import {sharePlay, shareDisplay, sharePublish} from './api'
import { copyToClipboard, shareOnFacebook, shareOnReddit, shareOnTwitter } from '../../utils'
import userStore from "../common/userStore"
import subscribeStore from "../account/subscribeStore"
import { addToMyLoop } from '../loops/LoopApi'



class ShareModel {

  loading = false

  fetching = false

  submiting = false

  shareData = null

  shareCode = ''

  shareDialog = null

  constructor() {
    makeAutoObservable(this)
  }

  get user() {
    return this.shareData ? this.shareData.user : {}
  }

  get loop() {
    return this.shareData ? this.shareData.loop : {}
  }

  get pack() {
    return this.shareData ? this.shareData.pack : {}
  }

  play = async (shareCode) => {
    try {
      this.submiting = true
      this.shareCode = shareCode
      await sharePlay({shareCode})
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => this.submiting = false)
    }
  }

  load = async (shareCode) => {
    try {
      this.shareData = null
      this.loading = true
      this.shareCode = shareCode
      // this.setUrl(shareCode)
      const data = await shareDisplay({shareCode})
      runInAction(() => this.shareData = data)
    } catch( e) {
      console.log(e)
    } finally {
      runInAction(() => this.loading = false)
    }
  }

  onAdd = async() => {
    if (userStore.isAnonymous) {
      userStore.setOpenSignup(true)
      return false;
    }
    await addToMyLoop({
      loopId: this.loop.id, 
      name: this.loop.name
    })
    return true;
  }

  onDownload = async() => {
    if (userStore.isAnonymous) {
      userStore.setOpenSignup(true)
      return false;
    }
    return await subscribeStore.downloadLoop(this.loop.id, this.loop)
  }

  onPlay = async() => {
    // 请求后台
    await this.play(this.shareCode)
  }
}


class ShareDialogModel {

  show = false

  url = ''

  copySuccess = false

  fetching = false

  loopIds = {}

  constructor() {
    makeAutoObservable(this)
  }

  showModal = (loopId, shareCode) => {
    this.reset()
    this.show = true
    if (shareCode && userStore.isAnonymous) {
      this.setUrl(shareCode)
      return;
    }
    if (userStore.isAnonymous) {
      userStore.setOpenSignup(true)
      this.show = false
      return;
    }
    this.loadUrl(loopId)
  }

  reset = () => {
    this.url = ''
    this.show = false
    this.copySuccess = false
  }

  hideModal = () => {
    this.reset()
  }

  setUrl = ( shareCode ) => {
    this.url = `https://sessionloops.com/s/${shareCode}`
  }

  setLoopShareCode = (loopId, shareCode) => {
    this.loopIds[loopId] = shareCode
  }

  loadUrl = async (loopId) => {
    try {
      if (this.loopIds[loopId]) {
        this.setUrl(this.loopIds[loopId])
        return this.url;
      }
      this.fetching = true
      const id = await sharePublish({loopId})
      this.setLoopShareCode(loopId, id)
      this.setUrl(id)
      return this.url
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => this.fetching = false)
    }
  }

  toClipboard = async () => {
    if (!this.url) {
      return;
    }
    this.copySuccess = false
    await copyToClipboard(this.url)
    this.copySuccess = true
  }

  toFacebook = () => {
    if (!this.url) {
      return;
    }
    shareOnFacebook(this.url)
  }

  toReddit = () => {
    if (!this.url) {
      return;
    }
    shareOnReddit(this.url)
  }

  toTwitter = () => {
    if (!this.url) {
      return;
    }
    shareOnTwitter(this.url)
  }
}

const shareDialogStore = new ShareDialogModel()

export { ShareModel, ShareDialogModel, shareDialogStore }