export const NUM_STEPS_PER_BEAT = 4;
export const NUM_BEATS_PER_BAR = 4;
export const NUM_STEPS_PER_BAR = NUM_STEPS_PER_BEAT * NUM_BEATS_PER_BAR;
export const MAX_BARS = 4;
export const PITCHES = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'];
export const NUMERALS = ['I', 'bII', 'II', 'bIII', 'III', 'IV', '#IV', 'V', 'bVI', 'VI', 'bVII', 'VII'];
export const MAJOR_TYPES_PRESET = {
    'I': ['maj7', 'maj7(9,13)', '69', 'maj', 'sus4', 'm7', '7'],
    'II': ['m7', 'm7(9,11)', 'min', 'm7b5', '7', '7(9,13)', 'maj7'],
    'III': ['m7', 'min', '7', '7sus4', '7(b9,b13)', 'maj', 'sus4'],
    'IV': ['maj7', 'maj7(9,13)', '69', 'maj', 'm7', 'm7(9,11)', '7'],
    'V': ['7', 'maj', '7sus4', '7(9,13)', '7(b9,b13)', 'min', 'm7'],
    'VI': ['m7', 'm7(9,11)', 'min', '7', '7sus4', '7(9,13)', 'maj7'],
    'VII': ['m7b5', 'min', 'm7', 'm7(9,11)', '7', '7sus4', '7(9,13)']
};
export const MAJOR_NUMERALS_PRESET = {
    'I': ['I', 'II', 'V', 'VII'],
    'II': ['II', 'V', 'VII', 'IV'],
    'III': ['III', 'III7', 'VII', 'I'],
    'IV': ['IV', 'II', 'V', 'VII'],
    'V': ['V', 'II', 'VII', 'I'],
    'VI': ['VI', 'VI7', 'I', 'VII'],
    'VII': ['VII', 'II', 'V', 'IV']
};
export const MAJOR_EXTRA_NUMERALS_PRESET = {
    'bII': ['IV', 'V', 'II', 'VII'],
    'bIII': ['I', 'II', 'V', 'VII'],
    '#IV': ['VII', 'V', 'II', 'I'],
    'bVI': ['IV', 'II', 'V', 'VII'],
    'bVII': ['V', 'II', 'VII', 'I']
};
export const NUMERAL_TYPES = {
    'I': 'maj7',
    'II': 'm7',
    'III': 'm7',
    'IV': 'maj7',
    'V': '7',
    'VI': 'm7',
    'VII': 'm7b5',
    'III7': '7',
    'VI7': '7'
};

export const MAJOR_TYPE_NUMERALS = {
    'maj':       ['I', 'IV', 'V', 'I', 'V', 'IV', 'I', 'V', 'IV', 'I', 'V', 'IV'],
    'sus4':      ['I', 'IV', 'V', 'I', 'V', 'IV', 'I', 'V', 'IV', 'I', 'V', 'IV'],
    'maj7':      ['I', 'IV', 'IV','I', 'IV','IV', 'IV', 'IV','IV', 'IV','IV','IV'],
    'maj7(9,13)':['I', 'IV', 'IV','I', 'IV','IV', 'IV', 'IV','IV', 'IV','IV','IV'],
    '69':        ['I', 'IV', 'IV','I', 'IV','IV', 'IV', 'IV','IV', 'IV','IV','IV'],
    'min':       ['VI','II','II','II','III','II', 'II',	'II','II', 'VI','II','II'],
    'm7':        ['VI','II','II','II','III','II', 'II',	'II','II', 'VI','II','II'],
    'm7(9,11)':  ['VI','II','II','II','III','II', 'II',	'II','II', 'VI','II','II'],
    '7':         ['V', 'V', 'V', 'V','III7','V',  'V',	'V', 'V',  'VI7','V','V' ],
    '7sus4':     ['V', 'V', 'V', 'V','III7','V',  'V',	'V', 'V',  'VI7','V','V' ],
    '7(9,13)':   ['V', 'V', 'V', 'V', 'V',  'V',  'V',	'V', 'V',  'V',  'V','V' ],
    '7(b9,b13)': ['III7','III7','III7','III7','III7','III7','III7','III7','III7','III7','III7','III7'],
    'm7b5':      ['VII','VII','VII','VII','VII','VII','VII','VII','VII','VII','VII','VII'],
    'dim7':      ['VII','VII','VII','VII','VII','VII','VII','VII','VII','VII','VII','VII'] 
};

export const TYPE_TENSIONS = {
    'maj': ['maj7', '7', '69'],
    'sus4': ['7sus4'],
    'maj7': ['maj7(9,13)'],
    'min': ['m7'],
    'm7': ['m7(9,11)'],
    '7': ['7(9,13)', '7(b9,b13)']
};

export const FX_CATEGORIES = [
  {
    "name": "Singles",
    "presets": [
      "Analog Echo (8th note, heavy)",
      "Analog Echo (8th note, light)",
      "Analog Echo (dotted 8th note, heavy)",
      "Analog Echo (dotted 8th note, light)",
      "Analog Echo (dotted quarter note, heavy)",
      "Analog Echo (dotted quarter note, light)",
      "Analog Echo (quarter note, heavy)",
      "Analog Echo (quarter note, light)",
      "Chorus (full)",
      "Chorus (half)",
      "Cloud Reverb (long heavy)",
      "Cloud Reverb (long light)",
      "Cloud Reverb (short heavy)",
      "Cloud Reverb (short light)",
      "Ensemble (full)",
      "Ensemble (half)",
      "Ocean Reverb (long heavy)",
      "Ocean Reverb (long light)",
      "Ocean Reverb (short heavy)",
      "Ocean Reverb (short light)",
      "Reversing Machine (8th note, heavy)",
      "Reversing Machine (8th note, light)",
      "Reversing Machine (quarter note, heavy)",
      "Reversing Machine (quarter note, light)",
      "Rotary (16th note, heavy)",
      "Rotary (16th note, light)",
      "Rotary (8th note, heavy)",
      "Rotary (8th note, light)",
      "Rotary (quarter note, heavy)",
      "Rotary (quarter note, light)",
      "Stone Phaser (8th note)",
      "Stone Phaser (Fast)",
      "Stone Phaser (Slow)",
      "Stone Phaser (quarter note)",
      "Stone Phaser (whole note)",
      "Sweep Delay (8th note trem)",
      "Sweep Delay (Default)",
      "Sweep Delay (quarter note trem, heavy delay)",
      "Sweep Delay (quarter note trem, light delay)",
      "Sweep Delay (whole note trem)",
      "Tape Saturator (heavy)",
      "Tape Saturator (light)",
      "Tremolo (16th note, heavy)",
      "Tremolo (16th note, light)",
      "Tremolo (8th note, heavy)",
      "Tremolo (8th note, light)",
      "Vibrato (fast heavy)",
      "Vibrato (fast light)",
      "Vibrato (slow heavy)",
      "Vibrato (slow light)"
    ]
  },
  {
    "name": "Combos",
    "presets": [
      "Echo Chorus (dotted 8th note)",
      "Echo Chorus (dotted quarter note)",
      "Echo Cloud (dotted 8th note)",
      "Echo Cloud (dotted quarter note)",
      "Echo Cloud (quarter note)",
      "Echo Ensemble",
      "Echo Ocean (dotted 8th note)",
      "Echo Ocean (quarter note)",
      "Echo Stone",
      "Echo Vibrato (dotted 8th note)",
      "Echo Vibrato (dotted quarter note)",
      "Reversing Chorus",
      "Reversing Cloud (8th note)",
      "Reversing Cloud (quarter note, heavy)",
      "Reversing Cloud (quarter note, light)",
      "Reversing Ensemble",
      "Reversing Ocean",
      "Reversing Rotary (dotted 8th note)",
      "Reversing Rotary (dotted quarter note)",
      "Reversing Stone",
      "Reversing Vibrato",
      "Sweep Ocean",
      "Tape Cloud",
      "Tape Echo"
    ]
  }
];