import React, { useRef } from 'react'
import { observer, useLocalObservable } from "mobx-react-lite"
import Popover from '@mui/material/Popover';
import Avatar from '@mui/material/Avatar';
import clsx from 'clsx';
import Link from '@mui/material/Link'
import classes from './UserAvatar.module.scss'
import userStore from '../common/userStore'
import { useNavigate } from '@reach/router'


const UserAvatar = observer(({className}) => {
  const domRef = useRef(null)
  const navigate = useNavigate()
  const state = useLocalObservable(() => ({
    open: false,
    handleOpen() {
      this.open = true
    },
    handleClose() {
      this.open = false
    }
  }))
  const {name, email, avatar} = userStore.user;

  const to = (link) => {
    state.handleClose()
    navigate(link)
  }

  return <>
    <Avatar ref={domRef} className={clsx(classes.avatar, className)} onClick={state.handleOpen} src={avatar}>{name && name.substr(0, 1).toUpperCase()}</Avatar>
    <Popover
      open={state.open}
      anchorEl={domRef.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={state.handleClose}
      disableRestoreFocus
      classes={{paper: classes.paper}}
    >
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Avatar className={classes.avatar2} src={avatar}>{name && name.substr(0, 1).toUpperCase()}</Avatar>
        </div>
        <div className={classes.headerRight}>
          <div className={classes.displayName}>{name}</div>
          <div className={classes.email}>{email}</div>
        </div>
      </div>
      <div className={classes.linkRow}><Link underline="none" onClick={() => to("/account/profile")}>Account Settings </Link></div>
      <div className={classes.linkRow}><Link underline="none" onClick={() => to("/account/plan")}>Plan </Link></div>
      <div className={classes.linkRow}><Link underline="none" onClick={() => to("/account/billing")}>Billing </Link></div>
      <div className={classes.linkRow}><Link underline="none" onClick={userStore.logout}>Log out </Link></div>
  </Popover>
</>
})

export default UserAvatar;