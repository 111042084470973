import { makeAutoObservable, runInAction } from "mobx"
// import {addSuccess, addError} from '../../components/Notifier'
import { getPlans, showSub, cancelSub, changePlan, createSub, fetchDownload } from './AccountAPI'
import userStore from "../common/userStore"
import { download } from '../../hooks/download'


class SubscribeModel {

  plans = []

  loading = false

  openedPlan = false

  currentSub = null

  downloading = false

  downloadLoopId = null

  constructor() {
    makeAutoObservable(this)
  }

  get subStatus() {
    if (!userStore.isLogin) {
      return "New"
    }
    const value = userStore.user.subStatus.toLowerCase()
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  
  get subStatusCheck() {
    const status = this.subStatus
    return {
      isNew: status == "New",
      isFree: status == "Free",
      isCancelled: status == "Cancelled",
      isActive: status == "Active",
      isTrial: status == "Trial",
    }
  }

  get showSubCheck() {
    if (this.currentSub && this.currentSub.subStatus) {
      const value = this.currentSub.subStatus.toLowerCase()
      const status = value.charAt(0).toUpperCase() + value.slice(1);
      return {
        isNew: status == "New",
        isFree: status == "Free",
        isCancelled: status == "Cancelled",
        isActive: status == "Active",
        isTrial: status == "Trial",
      }
    }
    return this.subStatusCheck
  }

  reloadSubStatus = async() => {
    await this.loadSub()
    await userStore.load()
  }

  showSubPlan = () => {
    this.openedPlan = true;
  }

  hideSubPlan = () => {
    this.openedPlan = false;
    this.downloading = false;
    this.downloadLoopId = null;
  }

  loadPlans = async(force) => {
    try {
      this.setLoading(true)
      if (!force && this.plans && this.plans.length > 0) {
        return this.plans;
      }
      const rs = await getPlans()
      this.setPlans(rs)
      return this.plans;
    } catch (e) {
      console.error(e)
      return this.plans;
    } finally {
      this.setLoading(false)
    }
  }

  fetchSub = async() => {
    return await showSub()
  }

  loadSub = async() => {
    try {
      const rs = await showSub()
      //console.log(rs)
      this.setSub(rs)
    } catch (e) {
      console.error(e)
    }
  }

  setLoading = (loading) => {
    this.loading = loading
  }

  setPlans = (plans) => {
    this.plans = plans || [];
  }

  setSub = (sub) => {
    this.currentSub = sub;
  }

  createSub = async(planId) => {
    return await createSub({planId})
  }

  changePlan = async(planId) => {
    return await changePlan({planId})
  }

  cancelPlan = async() => {
    return await cancelSub()
  }

  downloadLoop = async(loopId, loop, midi) => {
    // fetchDownload
    try {
      this.downloading = true
      this.downloadLoopId = loopId
      const rs = await fetchDownload({loopId, midi})
      download(rs, loop.name + '_' + loop.key + (loop.mode === 'major' ? '' : 'm') + '_BPM' + loop.bpm.toString())
      userStore.loadCredit()
    } catch (e) {
      // console.log('code', e.code, e.code == 1)
      // console.log('downloadLoop', e)–
      if (e.code == 1) {
        console.log(e)
        this.showSubPlan()
      }
      return false;
    }
    return true;
  }

}

const subscribeStore = new SubscribeModel()

export default subscribeStore