import React, { useEffect } from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import useWindowSize from '../hooks/window-size';

class MessagesModel {

  messages = []

  get open() {
    return this.messages.some(msg => msg.open)
  }

  get msg() {
    return this.messages.length > 0 ? this.messages[this.messages.length - 1] : {}
  }

  constructor() {
    makeAutoObservable(this)
  }

  addMessage = ({variant, message}) => {
    const idx = this.messages.push({variant, message, open:true})
    const msg = this.messages[idx - 1]
    setTimeout(() => {
      this.autoHide(msg)
    }, 3000)
    return true
  }

  autoHide = (msg) => {
    msg.open = false
    setTimeout(() => {
      this.delayRemove(msg)
    }, 300)
  }

  close = () => {
    this.messages.forEach(msg => this.autoHide(msg))
  }

  delayRemove = (msg) => {
    this.messages.remove(msg)
  }
}

const messagesStore = new MessagesModel()

let enqueueSnackbarProxy = (message, {variant}) => {
  messagesStore.addMessage({variant, message})
};

const addNotice = ({variant, message}) => {
  // console.log({variant, message})
  return enqueueSnackbarProxy(message, {variant})
}

const addError = (message) => {
  return addNotice({variant: 'error', message})
}

const addSuccess = (message) => {
  return addNotice({variant: 'success', message})
}

const Notifier = observer(() => {

  const msg = messagesStore.msg
  const { width } = useWindowSize()

  return <Snackbar 
    open={messagesStore.open} 
    anchorOrigin={{ vertical: width <= 500 ? 'bottom' : 'top', horizontal: 'center'}}>
    <Alert style={{fontSize: 14}} onClose={messagesStore.close} severity={msg.variant} variant="filled">
      {msg.message}
    </Alert>
  </Snackbar>;
});

export default Notifier;

export { addNotice, addError, addSuccess }