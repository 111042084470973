import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/common/userSlice';
import packPlayerReducer from '../features/common/packPlayerSlice';
import playerReducer from '../features/common/playerSlice';
import editorSlice from '../features/editor/editorSlice'

export default configureStore({
  reducer: {
    user: userReducer,
    packPlayer: packPlayerReducer,
    player: playerReducer,
    editor: editorSlice
  },
});
