import React, { useEffect } from 'react'
import { observer } from "mobx-react-lite"
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close';

import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import RedditIcon from '@mui/icons-material/Reddit';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Drawer from '@mui/material/Drawer';
import clsx from 'clsx'
import classes from './ShareDialog.module.scss'
import { shareDialogStore } from './model'
import useWindowSize from '../../hooks/window-size';
import userStore from '../common/userStore';
import { addSuccess } from '../../components/Notifier';
import subscribeStore from '../account/subscribeStore';


const ShareDrawer = observer(({model}) => {

  const toFacebook = () => {
    model.toFacebook()
    model.hideModal()
  }

  const toTwitter = () => {
    model.toTwitter()
    model.hideModal()
  }

  const toReddit = () => {
    model.toReddit()
    model.hideModal()
  }

  const toClipboard = async() => {
    await model.toClipboard()
    addSuccess('Link copied')
    model.hideModal()
  }

  return <>
  <Drawer open={model.show} classes={{paper: classes.paper}} anchor='bottom' onClose={model.hideModal}>
    <div className={classes.drawer}>
      <div className={classes.title}>Share this loop via</div>
      <div className={classes.shareTypes}>
        <div className={clsx(classes.iconWrap, classes.facebook)} onClick={toFacebook}><FacebookOutlinedIcon /></div>
        <div className={clsx(classes.iconWrap, classes.twitter)} onClick={toTwitter}><TwitterIcon /></div>
        <div className={clsx(classes.iconWrap, classes.reddit)} onClick={toReddit}><RedditIcon /></div>
        <div className={clsx(classes.iconWrap, classes.link)} onClick={toClipboard}><InsertLinkIcon /></div>
      </div>
      { userStore.isAnonymous ? null : <div className={classes.info}>
      For every 1 stream, you get 1 credit. <br /> You can get up to 15 credits per month.
      </div>}
    </div>
  </Drawer>
  </>
})

const ShareModal = observer(({model}) => {

  return <Modal open={model.show} onClose={model.hideModal}>
    <div className={clsx(classes.root)}>
      <div className={classes.head}><CloseIcon onClick={model.hideModal} /></div>
      <div className={classes.title}>Share this loop via</div>
      <div className={classes.shareTypes}>
        <div className={clsx(classes.iconWrap, classes.facebook)} onClick={model.toFacebook}><FacebookOutlinedIcon /></div>
        <div className={clsx(classes.iconWrap, classes.twitter)} onClick={model.toTwitter}><TwitterIcon /></div>
        <div className={clsx(classes.iconWrap, classes.reddit)} onClick={model.toReddit}><RedditIcon /></div>
      </div>
      <div className={classes.copyText}>Or copy link</div>
      <div className={classes.copyField}>
        <div className={classes.linkWrap}  onClick={model.toClipboard}><InsertLinkIcon className={classes.link} /></div>
        <InputBase className={classes.input} value={model.url} />
        <Button variant="contained" disableElevation className={classes.button} onClick={model.toClipboard}>Copy</Button>
      </div>
      {model.copySuccess ? <div className={classes.tips}>
          <CheckCircleOutlineIcon />
          Link copied
      </div> : null}
      {/* {model.copySuccess ? <Divider className={classes.divider} /> : null} */}
      { userStore.isAnonymous ? null : <div className={classes.info}>
      For every 1 stream, you get 1 credit. <br /> You can get up to 15 credits per month.
      </div>}
    </div>
  </Modal>
})

const ShareDialog = observer(() => {
  const {width} = useWindowSize();
  const model = shareDialogStore;

  if (width <= 500) {
    return <ShareDrawer model={model} />
  }
  return <ShareModal model={model} />
})

const ShareSubscribe = observer(() => {
  const model = shareDialogStore;
  const {downloading, downloadLoopId} = subscribeStore;
  const {width} = useWindowSize();

  const toFacebook = () => {
    model.toFacebook()
    if (width < 780){
      subscribeStore.hideSubPlan()
    }
  }

  const toTwitter = () => {
    model.toTwitter()
    if (width < 780){
      subscribeStore.hideSubPlan()
    }
  }

  const toReddit = () => {
    model.toReddit()
    if (width < 780){
      subscribeStore.hideSubPlan()
    }
  }

  const toClipboard = () => {
    model.toClipboard()
    if (width < 780){
      addSuccess('Link copied')
      subscribeStore.hideSubPlan()
    }
  }

  useEffect(() => {
    if (downloadLoopId) {
      model.reset()
      model.loadUrl(downloadLoopId)
    }
  }, [downloadLoopId])

  if (!downloadLoopId) {
    return null;
  }

  if (!downloading) {
    return null;
  }

  return <div className={classes.subscribe}>
    <Divider className={classes.divider}>or share this loop via</Divider>
    <div className={classes.shareTypes}>
      <div className={clsx(classes.iconWrap, classes.facebook)} onClick={toFacebook}><FacebookOutlinedIcon /></div>
      <div className={clsx(classes.iconWrap, classes.twitter)} onClick={toTwitter}><TwitterIcon /></div>
      <div className={clsx(classes.iconWrap, classes.reddit)} onClick={toReddit}><RedditIcon /></div>
      <div className={clsx(classes.iconWrap, classes.link)} onClick={toClipboard}><InsertLinkIcon /></div>
      <div className={classes.copyField}>
        <div className={classes.linkWrap} onClick={model.toClipboard}><InsertLinkIcon className={classes.linkIcon} /></div>
        <InputBase className={classes.input} value={model.url} />
        <Button variant="contained" disableElevation className={classes.button} onClick={model.toClipboard}>Copy</Button>
      </div>
      {model.copySuccess ? <div className={classes.tips}>
        <CheckCircleOutlineIcon /> Link copied
      </div> : null}
    </div>
    { userStore.isAnonymous ? null : <div className={classes.info}>
      For every 1 stream, you get 1 credit.  You can get up to 15 credits per month.
    </div>}
    { userStore.isAnonymous ? null : <div className={classes.info2}>
      For every 1 stream, you get 1 credit. <br /> You can get up to 15 credits per month.
    </div>}
  </div>
})

export { ShareDialog, ShareSubscribe, shareDialogStore }