import React from 'react'
import Slider from '@mui/material/Slider';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeDownOutlinedIcon from '@mui/icons-material/VolumeDownOutlined';
import VolumeMuteOutlinedIcon from '@mui/icons-material/VolumeMuteOutlined';

import useHover from '../../hooks/hover'
import usePlayer from './usePlayer'

import styles from './Volume.module.scss'
import clsx from 'clsx';


const Volume = ({isBottom, className}) => {
  const [hoverRef, isHovered] = useHover();
  const { volume, changeVolume } = usePlayer()
  const overrideClasses = {thumb: styles.thumb, track: styles.track, rail: styles.rail, focusVisible: styles.focusVisible}
  let Icon = VolumeUpOutlinedIcon
  if (volume < 50) {
    Icon = VolumeDownOutlinedIcon
  }
  if (volume < 1) {
    Icon = VolumeMuteOutlinedIcon
  }
  return <div className={clsx(styles.root, className)} ref={hoverRef}>
    <Icon className={isBottom ? styles.bottomVolume : styles.volume} viewBox="3 3 18 18" />
    <div className={isBottom ? styles.popBottom : styles.pop} style={{display: isHovered ? '' : 'none'}}>
      <div className={isBottom ? styles.overlayBottom : styles.overlay}></div>
      <Slider className={isBottom ? styles.controlBottom : styles.control} 
        classes={overrideClasses}
        orientation="vertical" 
        value={volume} 
        onChange={(e, value) => changeVolume(value)} />
    </div>
  </div>
}

export default Volume