import { createSlice } from '@reduxjs/toolkit'
import { getNumeral, getNumeralInMajor, checkFill } from './editorUtils'
import { PITCHES, MAJOR_TYPES_PRESET, MAJOR_NUMERALS_PRESET } from './editorConstants'

const initialState = {
  config: null,
  json: null,
  snapshot: null,
  status: 'editing',
  cycle: false,
  snap: true,
  fx: null,
  bars: 4,
  duration: 0, //in seconds
  key: 'C',
  mode: 'major',
  bpm: 60,
  clips: [],
  selectedClipIndex: -1,
  selectedChangeIndex: -1,
  presetsProgressions: [],
  myProgressions: []
};

export const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {

    resetEditor: (state) => ({...initialState, cycle: state.cycle, snap: state.snap, presetsProgressions: state.presetsProgressions, myProgressions: state.myProgressions}),

    takeSnapshot: (state) => {
      state.snapshot = {
        fx: state.fx,
        bars: state.bars,
        key: state.key,
        mode: state.mode,
        bpm: state.bpm,
        clips: [...state.clips]
      }
    },

    clearClips: (state) => {
      state.clips = [];
      state.selectedClipIndex = state.selectedChangeIndex = -1;
    },

    updateClips: (state, action) => {
      state.clips = [...action.payload];
      state.selectedClipIndex = state.selectedChangeIndex = -1;
    },

    setConfig: (state, action) => {
      state.config = {...action.payload};
    },

    loadJson: (state, action) => {
        state.json = {...action.payload};
        state.fx = action.payload.fx;
        state.bars = action.payload.bars;
        state.key = action.payload.key;
        state.mode = action.payload.mode;
        state.bpm = action.payload.bpm;
        state.clips = [...action.payload.clips];
        state.snapshot = {
          fx: action.payload.fx,
          bars: action.payload.bars,
          key: action.payload.key,
          mode: action.payload.mode,
          bpm: action.payload.bpm,
          clips: [...action.payload.clips]
        }
    },

    setStatus: (state, action) => {
      state.status = action.payload;
      state.selectedClipIndex = state.selectedChangeIndex = -1;
    },

    setCycle: (state, action) => {
      state.cycle = action.payload;
    },

    setSnap: (state, action) => {
      state.snap = action.payload;
    },

    setFx: (state, action) => {
      state.fx = action.payload;
    },

    resetFx: (state) => {
      state.fx = null;
    },

    setBars: (state, action) => {
      state.bars = action.payload;
      state.selectedClipIndex = state.selectedChangeIndex = -1;
    },

    setDuration: (state, action) => {
      state.duration = action.payload;
    },

    setKey: (state, action) => {
      if (state.key !== action.payload)
      {
        const increment = PITCHES.indexOf(action.payload) - PITCHES.indexOf(state.key) + PITCHES.length; 
        state.key = action.payload;
        for (const clip of state.clips)
          if (clip.type === 'groove')
            clip.changes.forEach((change, changeIndex) => {
              change.root = PITCHES[(PITCHES.indexOf(change.root) + increment) % PITCHES.length];
              checkFill(state.config, clip, change, changeIndex, state.key, state.mode);
            }); 
      }
    },

    setMode: (state, action) => {
      if (state.mode !== action.payload)
      {
        const numerals_in_major = Object.keys(MAJOR_TYPES_PRESET);
        for (const clip of state.clips)
          if (clip.type === 'groove')
          {
            const group = state.config.groups.find(group => group.id === clip.id && group.type === clip.type);
            clip.changes.forEach((change, changeIndex) => {
              let numeral_in_major = getNumeralInMajor(state.key, state.mode, change.root);
              if (numerals_in_major.includes(numeral_in_major))
              {
                let numeral = getNumeral(state.key, change.root);
                let increment = 0;
                if (numeral === 'bIII')
                  increment = 1;
                else if (numeral === 'III')
                  increment = -1;
                else if (numeral === 'bVI')
                  increment = 1;
                else if (numeral === 'VI')
                  increment = -1;
                else if (numeral === 'bVII')
                  increment = 1;
                else if (numeral === 'VII')
                  increment = -1;
                change.root = PITCHES[(PITCHES.indexOf(change.root) + increment + PITCHES.length) % PITCHES.length];
                numeral_in_major = getNumeralInMajor(state.key, action.payload, change.root);
                if (clip.numeral)
                  change.numeral = MAJOR_NUMERALS_PRESET[numeral_in_major].find(numeral => group.numerals.includes(numeral));
                else
                  change.type = group.types !== null ? MAJOR_TYPES_PRESET[numeral_in_major].find(type => group.types.includes(type)) : '';
                checkFill(state.config, clip, change, changeIndex, state.key, action.payload);
              }
            });
          }
          state.mode = action.payload;
      }
    },

    setBpm: (state, action) => {
      state.bpm = action.payload;
    },

    addClip: (state, action) => {
      if (state.clips.length === 0 && action.payload.beats !== 0)
      {
        const group = state.config.groups.find(group => group.id === action.payload.id && group.type === action.payload.type);
        state.bpm = group.bpm;
      }
      const index = state.clips.findIndex(clip => action.payload.loopStart < clip.loopStart);
      if (index === -1)
        state.clips.push(action.payload);
      else
        state.clips.splice(index, 0, action.payload);
      state.selectedClipIndex = state.selectedChangeIndex = -1;
    },

    removeClip: (state, action) => {
      state.clips.splice(action.payload, 1);
      state.selectedClipIndex = state.selectedChangeIndex = -1;
    },

    updateClip: (state, action) => {
      state.clips[action.payload.clipIndex] = {...action.payload.clip};
    },

    cutClip: (state, action) => {
      state.clips[action.payload.clipIndex].sampleEnd -= state.clips[action.payload.clipIndex].loopEnd - action.payload.clip.loopStart;
      state.clips[action.payload.clipIndex].loopEnd = action.payload.clip.loopStart;
      state.clips.splice(action.payload.clipIndex + 1, 0, action.payload.clip);
    },

    selectChange: (state, action) => {
      state.selectedClipIndex = action.payload.clipIndex;
      state.selectedChangeIndex = action.payload.changeIndex;
    },

    sortClip: (state) => {
      state.clips.sort((a, b) => a.loopStart - b.loopStart);
    },

    unselectChange: (state) => {
      state.selectedClipIndex = state.selectedChangeIndex = -1;
    },

    updateSelectedChange: (state, action) => {
      state.clips[state.selectedClipIndex].changes[state.selectedChangeIndex] = {...action.payload};
    },

    allPositionsLower: (state) => {
      for (const clip of state.clips)
      {
        const group = state.config.groups.find(group => group.id === clip.id && group.type === clip.type);
        clip.changes.forEach((change, changeIndex) => {
          if (change.position !== group.positions[0])
          {
            change.position = group.positions[group.positions.findIndex(position => position === change.position) - 1];
            checkFill(state.config, clip, change, changeIndex, state.key, state.mode);
          }
        });
      }
    },

    allPositionsHigher: (state) => {
      for (const clip of state.clips)
      {
        const group = state.config.groups.find(group => group.id === clip.id && group.type === clip.type);
        clip.changes.forEach((change, changeIndex) => {
          const index = group.positions.findIndex(position => position === change.position);
          if (index < group.positions.length - 1)
          {
            change.position =  group.positions[index + 1];
            checkFill(state.config, clip, change, changeIndex, state.key, state.mode);
          }
        });
      }
    },

    updatePresetsProgressions: (state, action) => {
      state.presetsProgressions = action.payload;
    },

    updateMyProgressions: (state, action) => {
      state.myProgressions = action.payload;
    },

  },
});

export const { 
  resetEditor, takeSnapshot, clearClips, updateClips, setConfig, loadJson,
  setStatus, setCycle, setSnap, setFx, resetFx, setBars, setDuration, setKey, setMode, setBpm,
  addClip, removeClip, updateClip, cutClip, selectChange, sortClip, unselectChange, updateSelectedChange,
  allPositionsLower, allPositionsHigher,
  updatePresetsProgressions, updateMyProgressions
} = editorSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

export default editorSlice.reducer;
