import React, { useEffect } from 'react'
import { observer } from "mobx-react-lite"
import clsx from 'clsx';
import Divider from '@mui/material/Divider';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from '@mui/material/Modal';
import { useNavigate } from "@reach/router"

import GoogleButton from './GoogleButton'
import FacebookButton from './FacebookButton';
import Password from '../account/Password'

import userStore from '../common/userStore';
import classes from './SignupModal.module.scss'
import {ReactComponent as Logo} from '../../logo2.svg'
import useSignup from './useSignup';
import CloseOutlined from '@mui/icons-material/CloseOutlined';

const SignupModal = observer(() => {
  const form = useSignup()
  const navigate = useNavigate()
  const onClose = () => {
    if (form.posting) {
      return;
    }
    userStore.setOpenSignup(false)
  }
  const inputClasses = {'root':classes.input, 'notchedOutline': classes.notchedOutline}

  return <Modal open={userStore.openSignup} onClose={onClose} classes={classes}>
    <div className={clsx(classes.paper, 'defaultFont')}>
        <div className={classes.head}> <CloseOutlined className={classes.close} onClick={onClose} /> </div>
      <div className={classes.logoWrapper}> 
        <Logo width="200" /> 
      </div>
      <div className={classes.signupTips}>
        Make custom loops today
      </div>
      <FormControl className={clsx(classes.field, classes.fieldEmail)}>
          <OutlinedInput 
            classes={inputClasses} 
            name="email"
            size="small" 
            placeholder="Email address" 
            onChange={form.handleEmail} 
            onKeyUp={form.handleKeyup} />
        </FormControl>
        <div className={classes.error}>
          {form.errors.email}
        </div>
        <FormControl className={classes.field}>
          <Password classes={inputClasses} 
            name="password"
            size="small" 
            type="password" 
            placeholder="Password: 8+ characters" 
            onChange={form.handlePassword} 
            onKeyUp={form.handleKeyup} />
        </FormControl>
        <div className={classes.error}>
          {form.errors.password}
        </div>

        <LoadingButton
          className={classes.submit}
          variant="contained" 
          disableElevation 
          loading={form.posting}
          onClick={form.handleSubmit}
          >Create Account</LoadingButton>

        <Divider className={classes.divider} light={true}>or continue with</Divider>

        <div className={classes.actions}>
          <GoogleButton className={classes.google} />
          <FacebookButton className={classes.facebook} />
        </div>

        <div className={classes.info}>
        By signing up, you agree to our <Link underline="none" onClick={() => window.open('/policies/terms-of-service', '_blank')}>Terms</Link> and <Link underline="none" onClick={() => window.open('/policies/privacy-policy', '_blank')}>Privacy Policy</Link>
        </div>
    </div>
  </Modal>
})

export default SignupModal;