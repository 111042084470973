import { createSlice } from '@reduxjs/toolkit'

const packPlayerSlice = createSlice({
  name: 'packPlayer',
  initialState: {
    playing: false,
    progress: 0,
    duration: 0,
    show: false,
    pack: {},
    url: null
  },
  reducers: {
    play: (state, action) => {
      const nowPack = state.pack || {}
      const playPack = action.payload || {}
      if (nowPack.id === playPack.id) {
        state.playing = !state.playing
        state.show = true
        return
      }
      state.pack = playPack
      state.playing = true
      state.progress = 0
      state.duration = 0
      state.show = true
      state.url = playPack.demo
    },
    togglePlaying: (state, action) => {
      if (state.url === null && !state.playing) {
        state.url = state.pack.demo
      }
      state.playing = !state.playing
    },
    closePlayer: (state, action) => {
      state.playing = false
      state.show = false
      state.url = null
      state.progress = 0
      state.pack = {}
    },
    updateProgress: (state, action) => {
      const {
        // loaded, loadedSeconds, playedSeconds, 
        played
      } = action.payload
      state.progress = played * 100
    },
    pause: (state, action) => {
      state.playing = false
    },
    loaded: (state, action) => {
      state.duration = action.payload
    }
  }
})

export const { play, togglePlaying, closePlayer, updateProgress, loaded, pause } = packPlayerSlice.actions;

export default packPlayerSlice.reducer