import React, { useCallback } from 'react'
import { useNavigate } from "@reach/router"

import PackCard from '../../components/PackCard'
import usePackPlayer from './usePackPlayer'
import {isMobile} from 'react-device-detect'

const PackPlayerCard = (props) => {
  const {isPlaying, playPack} = usePackPlayer()
  // const navigate = useNavigate();
  
  const onPlay = useCallback((e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    e.preventDefault();
    playPack(props.pack)
  }, [playPack, props.pack])

  // const onClick = useCallback((e) => {
  //   e.stopPropagation()
  //   e.nativeEvent.stopImmediatePropagation()
  //   e.preventDefault();
  //   const pathArgs = props.pack.url || props.pack.id
  //   navigate(`/pack/${pathArgs}`)
  // }, [navigate, props.pack])

  return <PackCard {...props} playing={isPlaying(props.pack)} onPlay={isMobile ? null : onPlay} href={`/pack/${props.pack.url || props.pack.id}`} />
}

export default PackPlayerCard