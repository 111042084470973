import React, { useCallback, useRef } from 'react'
import clsx from 'clsx'
import styles from './PackPlayer.module.scss'
import ReactPlayer from 'react-player'
import Slider from '@mui/material/Slider';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import TimeDisplay from '../../components/TimeDisplay'
import usePackPlayer from './usePackPlayer'
import Volume from './Volume';


const Player = () => {
  const player = usePackPlayer()
  const ref = useRef()

  const handleSeek = useCallback((e, value) => {
    ref.current.seekTo(value / 100)
  }, [])

  const PlayIcon = player.playing ? PauseCircleOutlineIcon : PlayCircleOutlineIcon

  const pack = player.pack

  const sliderOverride = {
    thumb: styles['progress-thumb'], 
    track: styles['progress-track'], 
    rail: styles['progress-rail']
  }
  const backImageStyle = pack && pack.cover ? {backgroundImage: `url(${pack.cover})`} : {}
  const defaultMp3 = process.env.PUBLIC_URL + '/nosound.mp3'
  return <div className={styles.root} style={{display: player.show ? '' : 'none'}}>
      <div className={styles.wrap}>
        <div className={clsx(styles.image, styles.cell)} style={backImageStyle}></div>
        <div className={styles.info}>
          <div className={styles.title}>{pack.title}</div>
          <div className={styles.tag}>{pack.tag}</div>
        </div>
        {/* <div className={styles.playWrap}> */}
        <PlayIcon className={clsx(styles.play, styles.cell)} onClick={() => player.playPack()} />
        <Slider className={clsx(styles.progress, styles.cell)} classes={sliderOverride}  value={player.progress} onChange={handleSeek} />
        <div className={clsx(styles.time, styles.cell)}>
          <TimeDisplay seconds={player.progress / 100 * player.duration} /> / <TimeDisplay className={styles['time-all']} seconds={player.duration} />
        </div>
        {/* </div> */}
        <CancelIcon className={styles.close} onClick={player.closePlayer} viewBox="2 2 20 20" />
        <ReactPlayer className={styles.instance} ref={ref} 
          progressInterval={300}
          id="react-player"
          playing={player.playing} 
          volume={player.volume / 100}
          url={player.url || defaultMp3}
          onProgress={player.setProgress}
          onDuration={player.onLoaded}
          onPause={player.pause} />
        <Volume isBottom={true}/>
      </div>
  </div>
}

export default Player