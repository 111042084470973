import React from 'react'
import { Link as RouterLink, useMatch, matchPath, useLocation } from "@reach/router";
import Link from '@mui/material/Link';
import clsx from 'clsx'

const useMatchPath = (to, as) => {
  const match = useMatch(to)
  const location = useLocation()
  if (!!match) {
    return true;
  }
  for (let resolvedPath of as) {
    let result = matchPath(resolvedPath, location.pathname);
    if (result) {
      return true;
    }
  }
  return false;
} 

const NavLink = props => {
  const {to, activeClass, className, active, as, ...ps} = props
  const match = useMatchPath(to, as || [])
  const activeClx = activeClass || 'active'
  return (
    <Link
      {...ps}
      to={to}
      underline="none"
      className={clsx(className, {[activeClx]: !!match || active})}
      component={RouterLink}
    />
  )
};

export default NavLink