// import { useState } from 'react'
// import { observer } from "mobx-react-lite"
// import { useSelector, useDispatch } from 'react-redux'
// import { setAuth, setUser, setLoading } from './userSlice'
// import { fetchUserInfo, doUserLogin, doUserLogout } from './UserAPI'
// import { useSnackbar } from 'notistack';
import userStore from '../common/userStore'

const useUser = () => {
  const user = userStore.detail
  const { login, logout, init } = userStore
  return {
    user, login, logout, init
  }
}

export default useUser