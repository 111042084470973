import React from 'react'
import { observer } from "mobx-react-lite"
import { Redirect, useLocation } from "@reach/router"
import useUser from './useUser'

const PrivateRoute = observer(props => {
  const {user} = useUser();
  const location = useLocation();
  let { as: Comp, ...ps } = props;
  if (user.auth === 1) {
    return <Comp {...ps} />
  }
  let rd = encodeURIComponent(location.href)
  return <Redirect to={`/auth/signup?rd=${rd}`} noThrow location={{search: ""}} />;
})

export default PrivateRoute;
