import { createApi } from '../common/API'

export const requestResetPassword = createApi('/user/requestResetPassword', {method: 'POST', expandData: true, notify: true, noThrow: false});
export const updateUser = createApi('/user/update', {method: 'POST', expandData: true, notify: true, noThrow: false});
export const requestVerifyEmail = createApi('/user/requestVerifyEmail', {method: 'POST', expandData: true, notify: true, noThrow: false});
export const requestChangeEmail = createApi('/user/requestChangeEmail', {method: 'POST', expandData: true, notify: true, noThrow: false});
export const verifyEmail = createApi('/user/verifyEmail', {method: 'POST', expandData: true, notify: true, noThrow: false});
export const changeEmail = createApi('/user/changeEmail', {method: 'POST', expandData: true, notify: true, noThrow: false});


export const queryBilling = createApi('/trade/queryBilling', {method: 'POST', expandData: true, notify: true, noThrow: false});
export const getPlans = createApi('/subscription/getPlans', {method: 'POST', expandData: true, notify: true, noThrow: false});
export const showSub = createApi('/subscription/showSub', {method: 'POST', expandData: true, notify: false, noThrow: false});
export const createSub = createApi('/subscription/createSub', {method: 'POST', expandData: true, notify: true, noThrow: false});
export const cancelSub = createApi('/subscription/cancelSub', {method: 'POST', expandData: true, notify: true, noThrow: false});
export const changePlan = createApi('/subscription/changePlan', {method: 'POST', expandData: true, notify: true, noThrow: false});
export const queryTransactions = createApi('/subscription/queryTransactions', {method: 'POST', expandData: true, notify: true, noThrow: false});
export const showOrders = createApi('/app/showOrders', {method: 'POST', expandData: true, notify: true, noThrow: false});

export const fetchDownload = createApi('/privilege/download', {method: 'POST', expandData: true, notify: false, noThrow: false});


export const fetchApps = createApi('/app/getApps', {method: 'POST', expandData: true, notify: false, noThrow: false});
export const generateAppToken = createApi('/app/generateToken', {method: 'POST', expandData: true, notify: false, noThrow: false});
export const createAppOrder = createApi('/app/createOrder', {method: 'POST', expandData: true, notify: false, noThrow: true});
export const redeemAppOrder = createApi('/app/createOrderWithCode', {method: 'POST', expandData: false, notify: false, noThrow: true});
export const redeemCreditOrder = createApi('/app/createCreditsOrderWithCode', {method: 'POST', expandData: false, notify: false, noThrow: true});
export const showAppOrder = createApi('/app/showOrder', {method: 'POST', expandData: true, notify: false, noThrow: false});

export const captureOrder = createApi('/subscription/capture', {method: 'POST', expandData: true, notify: false, noThrow: true});