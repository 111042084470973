import { createSlice } from '@reduxjs/toolkit'


const VOLUME_KEY = 'globalVolume'

const defaultVolume = (function() {
  try {
    return parseInt(window.localStorage.getItem(VOLUME_KEY) || '100')
  } catch (e) {
    return 100
  }
})()

const playerSlice = createSlice({
  name: 'player',
  initialState: {
    type: null,
    volume: defaultVolume
  },
  reducers: {
    playType: (state, action) => {
      state.type = action.payload
    },
    changeVolume: (state, action) => {
      state.volume = action.payload
      // TODO to localstrage
      window.localStorage.setItem(VOLUME_KEY, action.payload)
    }
  }
})


export const { playType, changeVolume } = playerSlice.actions;

export default playerSlice.reducer