import React, { useEffect, useState } from 'react'
import { observer, useLocalObservable } from "mobx-react-lite"
import { useNavigate } from "@reach/router"

import Snackbar from '@mui/material/Snackbar';
import useLocalStorage from '../../hooks/local-storage';

import classes from './CookieAgree.module.scss'


const CookieAgree = observer(() => {
  const navigate = useNavigate()
  const [agree, setAgree] = useLocalStorage('cookieAgree', '0')
  const open = agree !=  '1'
  const msg = <div className={classes.content}>By using our website, you agree to the use of cookies as described in our 
  <a className={classes.policy} href="/policies/privacy-policy" onClick={(e) => navigate(e.target.href)}>Privacy Prolicy</a> - 
  <a className={classes.agree} onClick={() => setAgree('1')}>I agree</a></div>;

  return <Snackbar anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'center',
  }} classes={{root: classes.root}} open={open} message={msg} />
})

export default CookieAgree;