import React from 'react'
import clsx from 'clsx'
import styles from './PackCard.module.css'
import useHover from '../hooks/hover'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';


const PackCard = ({className, pack, playing, onClick, onPlay, href}) => {
  const [hoverRef, isHovered] = useHover();
  const Icon = playing ? PauseCircleFilledIcon : PlayCircleFilledIcon
  const backImageStyle = pack && pack.cover ? {backgroundImage: `url(${pack.cover})`} : {}
  const isHoveredTrue = isHovered && onPlay
  return <a className={clsx(styles.root, className)} onClick={onClick} href={href}>
    <div className={styles.card}  ref={hoverRef}>
      {pack.free ? <div className={styles.free}>FREE</div> : ''}
      <div className={styles.cardBg} style={backImageStyle}></div>
      <div className={styles.cardTitle}>{pack.title}</div>
      <div className={styles.cardImage} style={backImageStyle}></div>
      <div className={styles.cardHover} style={{display: isHoveredTrue ? '' : 'none'}}></div>
      <Icon className={styles.cardPlay} style={{display: isHoveredTrue ? '' : 'none'}} onClick={onPlay} />
    </div>
    <div className={styles.title}>{pack.title}</div>
    <div className={styles.tag}>{pack.tag}</div>
  </a>
}

export default PackCard;