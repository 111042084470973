import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { playType as playTypeAction, changeVolume as changeVolumeAction } from './playerSlice'

const usePlayer = () => {
  const player = useSelector(state => state.player)
  const dispatch = useDispatch()

  const playType = useCallback((type) => {
    dispatch(playTypeAction(type))
  }, [dispatch])

  const changeVolume = useCallback((volume) => {
    dispatch(changeVolumeAction(volume))
  }, [dispatch])

  return {
    volume: player.volume,
    type: player.type,
    playType,
    changeVolume
  }
}

export default usePlayer