import React from 'react'
import { observer } from "mobx-react-lite"
import Button from '@mui/material/Button';
import {GoogleOAuthProvider, GoogleLogin, useGoogleLogin} from '@react-oauth/google';
import {ReactComponent as GoogleIcon} from '../../images/google.svg';
import userStore from '../common/userStore';


const GoogleButtonInner = ({className, onSuccess}) => {
  // const login = useGoogleLogin({
  //   onSuccess: onSuccess,
  //   flow: 'auth-code',
  // });
  // return <Button className={className} startIcon={<GoogleIcon width="24px" />} onClick={login}>Google</Button>
  return <Button className={className} startIcon={<GoogleIcon width="24px" />}>
    Google
    <GoogleLogin
        containerProps={{className: 'google-login'}}
        onSuccess={onSuccess}
    />
  </Button>
}

// login signup
const GoogleButton = observer(({className}) => {
  const responseGoogle = async(response) => {
    console.log(response);
    if (response.error) {
      return;
    }
    const rs = await userStore.loginByGoogle(response)
    if (rs) {
      console.log('google redirect to home')
      window.location.replace('/')
    }
  }

  return ( <GoogleOAuthProvider clientId="65864867659-3od8elaisbjr3hcv130e27nut7kuh8sa.apps.googleusercontent.com">
    <GoogleButtonInner className={className} onSuccess={responseGoogle} />
    </GoogleOAuthProvider>)
})
  

export default GoogleButton