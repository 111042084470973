import React, { useCallback, useEffect } from 'react'
import { observer, useLocalObservable } from "mobx-react-lite"
import { navigate } from "@reach/router"
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import clsx from 'clsx'
import GraphicEqIcon from '@mui/icons-material/GraphicEq';

import classes from './SubscribeModal.module.scss'
import subscribeStore from './subscribeStore';
import userStore from '../common/userStore';

import { ShareSubscribe } from '../share/ShareDialog'


const PlanItem = observer(({credits, price, period, title, to, id}) => (
<div className={classes.plan}>
  <div className={classes.planTop}>
    <div className={classes.topBg}></div>
    <div className={classes.topCr}></div>
  </div>
  <div className={classes.planDetail}>
    <h4>{title}</h4>
    <div className={classes.credits}>{credits}</div>
    <div className={classes.tips}>credits</div>
    <div className={classes.price}>
      <span className={classes.money}>{'$' + price}</span> / {period}
    </div>
    <Button className={classes.pay} variant="contained" disableElevation  onClick={() => to(id)}>Get Plan</Button>
  </div>
</div>
))

const PlanItemMobile = observer(({credits, price, period, title, to, id}) => (
<div className={classes.mobilePlan}>
  <div className={classes.mobilePlanIconCol}>
    <div className={classes.mobilePlanIcon}><GraphicEqIcon /></div>
  </div>
  <div className={classes.mobilePlanTitleCol}>
    <div className={classes.mobilePlanTitle}>{title}</div>
    <div className={classes.mobilePlanCredits}>
    {credits} credits
    </div>
  </div>
  <div className={classes.mobilePlanPayCol}>
    <div className={classes.mobilePlanPrice}> {'$' + price}/{period}</div>
    <Button className={classes.mobilePlanPay} variant="contained" disableElevation  onClick={() => to(id)}>Get Plan</Button>
  </div>
</div>
))

const NewTitle = () => {
  return <>
    <h2>Start your free 14-day trial</h2>
    <div className={classes.desc}>
      <span>You get 75 credits on us.</span> <span>you can cancel at any time.</span>
    </div>
  </>
}

const CancelledTitle = () => {
  return <>
    <h2>You need more credits</h2>
    <div className={classes.desc}>
    <span>You have 0 credits. </span><span>You need 1 to complete your download.</span>
    </div>
  </>
}

const ActiveTitle = () => {
  return <>
    <h2>Choose a plan</h2>
    <div className={classes.desc}>
      
    </div>
  </>
}

const SubscribeModal = observer(() => {
  const { isLogin } = userStore;
  const { openedPlan, loadPlans, hideSubPlan, plans, subStatusCheck, downloading } = subscribeStore;

  useEffect(() => {
    if (isLogin && openedPlan) {
      loadPlans()
    }
  }, [loadPlans, isLogin, openedPlan])

  const toCheckout = useCallback((id) => {
    const path = window.location.pathname;
    navigate(`/account/checkout?plan-id=${id}`, {replace: path.includes('/account/checkout')})
    hideSubPlan()
  }, [])

  if (!isLogin) {
    return null;
  }

  if (downloading && (subStatusCheck.isActive || subStatusCheck.isTrial)) {
    // if (downloading) {
    return <Modal
        open={openedPlan}
        onClose={hideSubPlan}
      >
        <div className={clsx(classes.root, classes.contactUs)}>
          <h3>You don't have enough credits </h3>
          <p>It may make take several minutes to process your order. </p>
          <p>Still need more credits? <Link href="mailto:support@sessionloops.com">Contact us</Link></p>
        </div>
      </Modal>
  }

  return <Modal
    open={openedPlan}
    onClose={hideSubPlan}
  >
  <div className={classes.root}>
    {(subStatusCheck.isNew) && <NewTitle />}
    {(subStatusCheck.isActive || subStatusCheck.isTrial) && <ActiveTitle />}
    {downloading && (subStatusCheck.isFree || subStatusCheck.isCancelled) && <CancelledTitle />}
    {!downloading && (subStatusCheck.isFree || subStatusCheck.isCancelled) && <ActiveTitle />}
    <div className={classes.plans}>
      {plans.map((plan, idx) => {
        if (idx >= 3) {
          return null;
        }
        return <PlanItem key={plan.id} id={plan.id} 
          title={plan.name} credits={plan.credit} 
          price={plan.price} period={plan.type == 0 ? "Monthly" : "Yearly"} 
          to={toCheckout} />
      })}
    </div>
    <div className={classes.mobilePlans}>
      {plans.map((plan, idx) => {
        if (idx >= 3) {
          return null;
        }
        return <PlanItemMobile key={plan.id} id={plan.id} 
          title={plan.name} credits={plan.credit} 
          price={plan.price} period={plan.type == 0 ? "mo" : "yr"} 
          to={toCheckout} />
      })}
    </div>
    {(downloading) && <ShareSubscribe />}
    <div className={classes.question}>
      <Link underline="hover" href="/help/faq" target="_blank">Still have questions? Learn more about plans</Link>
    </div>
  </div>
</Modal>
})

export default SubscribeModal;