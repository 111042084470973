import React, { useEffect, useRef } from 'react'
import { observer, useLocalObservable } from "mobx-react-lite"
import { useNavigate } from "@reach/router"

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {ReactComponent as MenuIcon} from '../../images/mb/menu.svg'
import classes from './Header.module.scss'
import clsx from 'clsx'
import ClearIcon from '@mui/icons-material/Clear';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import {ReactComponent as DiscordIcon} from '../../images/discord-white.svg'
import Video, {useVideo} from '../help/video';

const WelcomeDrawer = observer(({model}) => {
  const navigate = useNavigate()
  const handleNav = (e) => {
    e.preventDefault()
    navigate(e.target.href)
  }
  const videoModel = useVideo();
  return <>
    <IconButton className={classes.menu} onClick={model.toggleDrawer}>
      {model.open ? <ClearIcon className={classes.close} /> : <MenuIcon />}
    </IconButton>
    <SwipeableDrawer 
      open={model.open} 
      onOpen={model.toggleDrawer} 
      onClose={model.toggleDrawer} 
      anchor="right"
      hideBackdrop={true}
      classes={{paper: classes.drawerPaper, root: classes.drawerRoot}} onClick={(e) => e.stopPropagation()}>
      <div>
        <div className={classes.linkRow}><Link underline="none" onClick={() => navigate("/drumnet")}>DrumNet</Link></div>
        <div className={classes.linkRow}><Link underline="none" onClick={() => navigate("/packs")}>Custom Packs</Link></div>
        <div className={classes.linkRow}><Link underline="none" onClick={() => navigate("/auth/login")}>Log in</Link></div>
        <div className={classes.linkRow}><Link underline="none" onClick={() => navigate("/auth/signup")}>Sign up</Link></div>
        <div className={classes.linkRow}><Link underline="none" onClick={() => navigate("/reviews")}>Reviews</Link></div>
        <div className={classes.linkRow}><Link underline="none" onClick={() => navigate("/pricing")}>Pricing</Link></div>
        <div className={classes.linkRow}><Link underline="none" onClick={() => navigate("/help/faq")}>FAQ</Link></div>
      </div>
      <div className={classes.drawerPaperFooter}>
        <div className={classes.copyright}>© 2024 Session Loops, Inc. All rights reserved</div>
        <div className={classes.policies}>
          <a href="/policies/terms-of-service" onClick={handleNav}>Terms of Service</a>
          <a href="/policies/privacy-policy" onClick={handleNav}>Privacy Policy</a>    
          <a href="mailto:contact@sessionloops.com">Contact</a>
          <a href="https://sessionloops.firstpromoter.com" target="_blank">Affiliates</a>
        </div>
        <div className={classes.social}>
          <a href="https://www.facebook.com/SessionLoops" target="_blank"><FacebookIcon /></a>
          <a href="https://twitter.com/SessionLoops" target="_blank"><TwitterIcon /></a>
          <a href="https://www.youtube.com/channel/UCy-OnrvyjS9QyNwUQhd6Okw" target="_blank"><YouTubeIcon /></a>
          <a href="https://instagram.com/session_loops" target="_blank"><InstagramIcon /></a>
          <a href="https://discord.gg/REEHXucYTb" target="_blank"><DiscordIcon style={{ width: 24, height: 24}}/></a>
        </div>
      </div>
    </SwipeableDrawer>
    <Video open={videoModel.open} onClose={videoModel.hide} />
  </>
})


const Header = observer(({fix, normal}) => {
  const headerRef = useRef(null)
  const navigate = useNavigate()
  const model = useLocalObservable(() => ({
    open: false,
    toggleDrawer(e) {
      e.stopPropagation()
      this.open = !this.open
    },
    close() {
      if (this.open)
        this.open = false
    }
  }))

  const navTo = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    navigate(e.target.href)
  }

  useEffect(() => {
    let ob = new MutationObserver(function(mutations) {
        mutations.forEach(function(record) {
            if (headerRef.current) {
              headerRef.current.style.right = window.getComputedStyle(record.target)['padding-right'];
            }
        })
    });
    ob.observe(window.document.body, {attributes : true, attributeFilter : ['style']})

    return () => {
      ob.disconnect()
    }
  }, [headerRef])

  const container = <div className={classes.container}>
      <div className={classes.logo} onClick={() => navigate('/')}></div>
      <div className={classes.actions}>
        <Button className={classes.pricing} href="/drumnet" onClick={navTo}>DrumNet</Button>
        <Button className={classes.pricing} href="/packs" onClick={navTo}>Custom Packs</Button>
        <Button className={classes.pricing} href="/reviews" onClick={navTo}>Reviews</Button>
        {/* <Button className={classes.pricing} href="/reviews" onClick={navTo}>Reviews</Button> */}
        <Button className={classes.pricing} href="/pricing" onClick={navTo}>Pricing</Button>
        <Button className={classes.login} href="/auth/login" onClick={navTo}>Log in</Button>
        <Button className={classes.signup} href="/auth/signup" variant="outlined" onClick={navTo}>Try for free</Button>
        <WelcomeDrawer model={model} />
      </div>
    </div>
  
  return <>
    <Slide in={fix}>
      <header className={clsx(classes.header, normal && classes.normal, fix && classes.headerFix, model.open && classes.open)} onClick={model.close}>
        {container}
      </header>
    </Slide>
    <header ref={headerRef} className={clsx(classes.header, normal && classes.normal, !fix && classes.headerStatic, model.open && classes.open)} onClick={model.close}>
      {container}
    </header>
  </>
})

export default Header;