import React, {useState, useEffect, useCallback} from 'react';
import { NUM_STEPS_PER_BAR, NUM_BEATS_PER_BAR, NUM_STEPS_PER_BEAT, PITCHES, NUMERALS, MAJOR_TYPE_NUMERALS, NUMERAL_TYPES } from './editorConstants'

export function isChangeAvailable(clip, change, bars){
    const changeIndex = clip.changes.indexOf(change);
	if (changeIndex === -1)
		return false;
	return clip.sampleStart < (changeIndex  + 1 === clip.changes.length ? (clip.beats !== 0 ? clip.beats * NUM_STEPS_PER_BEAT : clip.sampleEnd) : clip.changes[changeIndex + 1].start)
			&& clip.sampleEnd > change.start && bars * NUM_STEPS_PER_BAR > clip.loopStart + change.start - clip.sampleStart;
}

export function getProgression(clips, bars)
{
	const progression = []
	clips.forEach((clip, clipIndex) => {
        if (clip.type === 'groove')
            clip.changes.forEach((change, changeIndex) => {
				if (isChangeAvailable(clip, change, bars)) 
					progression.push(
						{
							root: change.root, 
							type: clip.numeral ? NUMERAL_TYPES[change.numeral]: change.type,
							clipIndex: clipIndex,
							changeIndex: changeIndex
						});
			});
    });
	return progression;
}

export function getProgressionJSX(progression, key, numeral = true, threshold = 8, width = 'auto')
{
	const separator = convertUnicode('\u2022');
	if (progression.length > threshold)
		width = 'auto';
	return progression.map((change, index) => 
	<div key={index} style={{ whiteSpace: 'nowrap', marginRight: (progression.length <= threshold || !numeral) && width === 'auto' ? '5px' : 0, 
	pointerEvents: 'none', width: width, transform: width === 'auto' ? 'none': 'translateY(2px)'}}>
		{numeral ? getNumeralJSX(getNumeral(key, change.root), change.type) : <>{getPitchJSX(change.root)}{getTypeJSX(change.type)}</>}{index + 1 === progression.length || width !== 'auto' ? '' 
		: (progression.length <= threshold || !numeral ? ' ' +  separator : separator)}
	</div>);
}

export function getChangeEnd(clip, changeIndex)
{
	return changeIndex + 1 === clip.changes.length ? (clip.beats !== 0 ? clip.beats * NUM_STEPS_PER_BEAT : clip.sampleEnd) : clip.changes[changeIndex + 1].start;
}

export function getChangeLength(clip, changeIndex)
{
	const change = clip.changes[changeIndex];
	const changeEnd = getChangeEnd(clip, changeIndex);
	return (changeEnd > clip.sampleEnd ? clip.sampleEnd : changeEnd) - (clip.sampleStart > change.start ? clip.sampleStart : change.start);
}

export function transformStepToMeter(step, start = 0)
{
	const bar = Math.floor(step / NUM_STEPS_PER_BAR);
	const beat = Math.floor(step / NUM_STEPS_PER_BEAT) % NUM_BEATS_PER_BAR;
	const subdivision = step % NUM_STEPS_PER_BEAT;
	return (bar + start).toString() + '.' + (beat + start).toString() + '.' + (subdivision + start).toString();
}

export function getNumeral(key, root)
{
	return NUMERALS[(PITCHES.indexOf(root) - PITCHES.indexOf(key) + NUMERALS.length) % NUMERALS.length];
}

export function getNumeralInMajor(key, mode, root)
{
	return  NUMERALS[(NUMERALS.indexOf(getNumeral(key, root)) + NUMERALS.indexOf(mode === 'major' ? 'I' : 'VI')) % NUMERALS.length];
}

export function getRealRoot(group, root)
{
	return group.roots.find(realRoot => {
		let i;
		for (i = parseInt(realRoot.shift[0]); i <= parseInt(realRoot.shift[1]); i++)
			if (PITCHES[(PITCHES.indexOf(realRoot.pitch.replace('Gb', 'F#')) + i + PITCHES.length) % PITCHES.length] === root)
				return true;
		return false;
	});
}

export function getAvailableFills(config, group, clip, change, selectedChangeIndex, change_numeral_in_major)
{
	let fills = [];
	const fillNumeral = clip.numeral ? change.numeral : MAJOR_TYPE_NUMERALS[change.type][NUMERALS.indexOf(change_numeral_in_major)];
	if (fillNumeral !== null)
	{
		const root = getRealRoot(group, change.root);
		
		for (const fill of group.changes[selectedChangeIndex].fills)
		{
			const fillGroup = config.groups.find(group => group.id === fill && group.type === 'fill');
			for (const sample of fillGroup.samples)
			{
				if (sample.pitch === root.pitch && sample.position === change.position && sample.numerals.includes(fillNumeral))
				{
					fills.push(fillGroup.id);
					break;
				}
			}
		}
	}
	return [fills, fillNumeral];
}

export function checkFill(config, clip, change, changeIndex, key, mode)
{
  if (clip.type === 'groove' && change.fill !== null)
  {
    const group = config.groups.find(group => group.id === clip.id && group.type === clip.type);
    const [fills, fillNumeral] = getAvailableFills(config, group, clip, change, changeIndex, getNumeralInMajor(key, mode, change.root));
    if (fills.length === 0 || !fills.includes(change.fill))
    	change.fill = null;
	else
		change.numeral = fillNumeral;
  }
}

export function getPitchJSX(pitch)
{
	return (
		pitch.length === 2 ? 
		<React.Fragment>
		{pitch.charAt(0)}<sup>{convertUnicode(pitch.charAt(1) === 'b' ? '\u266d' : '\u266f')}</sup>
		</React.Fragment> :
		pitch
	);
}

export function getTypeJSX(type)
{
	if (type === 'm7b5')
		return <React.Fragment>m7<sup>{convertUnicode('\u266d')}</sup>5</React.Fragment>;
	else if (type === '7(b9,b13)')
		return <React.Fragment>7(<sup>{convertUnicode('\u266d')}</sup>9,<sup>{convertUnicode('\u266d')}</sup>13)</React.Fragment>
	else
		return type;
}

export function getNumeralJSX(numeral, type = '')
{
	const main = numeral.includes('b') || numeral.includes('#') ? <React.Fragment><sup>{convertUnicode(numeral.includes('b') ? '\u266d' : '\u266f')}</sup>{numeral.substring(1)}</React.Fragment> : numeral;
	//const left = numeral.includes('b') ? (document.fonts.check('12px BlinkMacSystemFont') || (document.fonts.check('12px Segoe UI')? '-6px' : '-12px') : (numeral.includes('#') ? (document.fonts.check('12px BlinkMacSystemFont') ? '-8px' : '-7px') : 0);
	let suffix;
	if (type === 'maj')
		suffix = <sup>{convertUnicode('\u2206')}</sup>;
	else if(type === 'sus4')
		suffix = <sup>sus4</sup>;
	else if(type === 'maj7' || type === 'maj7(9,13)')
		suffix = <sup>{convertUnicode('\u2206') + '7'}</sup>;
	else if(type === '69')
		suffix = <sup>69</sup>;
	else if(type === 'min')
		suffix = '-';
	else if(type === 'm7' || type === 'm7(9,11)')
		suffix = <React.Fragment>-<sup>7</sup></React.Fragment>;
	else if(type === '7' || type === '7(b9,b13)' || type === '7(9,13)')
		suffix = <sup>7</sup>;
	else if(type === '7sus4')
		suffix = <sup>7sus4</sup>;
	else if(type === 'm7b5')
		suffix = <sup>{convertUnicode('\u00f8') + '7'}</sup>
	else if(type === 'dim7')
		suffix = <sup>{convertUnicode('\u006f') + '7'}</sup>
	return <React.Fragment>{main}{suffix}</React.Fragment>;
}

export function convertUnicode(input) {
	return input.replace(/\\u[0-9a-fA-F]{4}/g,function(a,b) {
	  var charcode = parseInt(b,16);
	  return String.fromCharCode(charcode);
	});
}

export function useKeyPressing(targetKey, targetKeyCode = -1) {
	// State for keeping track of whether key is pressed
	const [keyPressed, setKeyPressed] = useState(false);
	
	// If pressed key is our target key then set to true
	const downHandler = useCallback(({ key, keyCode }) => {
		if (key === targetKey || keyCode === targetKeyCode) {
		  setKeyPressed(true);
		}
	}, [targetKey, targetKeyCode]);
	// If released key is our target key then set to false
	const upHandler = useCallback(({ key, keyCode }) => {
	  if (key === targetKey|| keyCode === targetKeyCode) {
		setKeyPressed(false);
	  }
	}, [targetKey, targetKeyCode]);
	// Add event listeners
	useEffect(() => {
	  window.addEventListener("keydown", downHandler);
	  window.addEventListener("keyup", upHandler);
	  // Remove event listeners on cleanup
	  return () => {
		window.removeEventListener("keydown", downHandler);
		window.removeEventListener("keyup", upHandler);
	  };
	}, [downHandler, upHandler]); // Empty array ensures that effect is only run on mount and unmount
	return keyPressed;
}

export function bufferToWav(abuffer, len) {
	let numOfChan = abuffer.numberOfChannels,
	length = len * numOfChan * 2 + 44,
	buffer = new ArrayBuffer(length),
	view = new DataView(buffer),
	channels = [], i, sample,
	offset = 0,
	pos = 0;

	// write WAVE header
	setUint32(0x46464952);                         // "RIFF"
	setUint32(length - 8);                         // file length - 8
	setUint32(0x45564157);                         // "WAVE"

	setUint32(0x20746d66);                         // "fmt " chunk
	setUint32(16);                                 // length = 16
	setUint16(1);                                  // PCM (uncompressed)
	setUint16(numOfChan);
	setUint32(abuffer.sampleRate);
	setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
	setUint16(numOfChan * 2);                      // block-align
	setUint16(16);                                 // 16-bit (hardcoded in this demo)

	setUint32(0x61746164);                         // "data" - chunk
	setUint32(length - pos - 4);                   // chunk length

	// write interleaved data
	for(i = 0; i < abuffer.numberOfChannels; i++)
		channels.push(abuffer.getChannelData(i));

	while(pos < length) {
		for(i = 0; i < numOfChan; i++) {             // interleave channels
			sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
			sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767)|0; // scale to 16-bit signed int
			view.setInt16(pos, sample, true);          // write 16-bit sample
			pos += 2;
		}
		offset++                                     // next source sample
	}
	// create Blob
	return new Blob([buffer], {type: "audio/wav"});

	function setUint16(data) {
		view.setUint16(pos, data, true);
		pos += 2;
	}

	function setUint32(data) {
		view.setUint32(pos, data, true);
		pos += 4;
	}
}

export function calculateDurationInSeconds(bpm, bars)
{
	return bars * 4 * 60 / bpm;
}

export function shuffleArray(array) {
	return array.map(value => ({ value, sort: Math.random() }))
	.sort((a, b) => a.sort - b.sort)
	.map(({ value }) => value);
}