import React, { useCallback } from 'react'
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import { useNavigate } from "@reach/router"

import styles from './HomeBanner.module.scss'

const HomeBanner = (props) => {
  // const navigate = useNavigate()
  const { item } = props

  // const toPack = useCallback(() => {
  //   navigate(`/pack/${item.url || item.packId}`)
  // }, [navigate, item])

  return <a className={styles.root} href={`/pack/${item.url || item.packId}`}>
    <div className={styles.image} style={{backgroundImage: `url(${item.banner})`}}></div>
    <div className={styles.info}>
      <Typography variant="h1">{item.title}</Typography>
      <Typography variant="h4" component="div" className={styles.p}>
      {item.content}
      </Typography>
      <Button variant="contained" size="large"
        classes={{endIcon: styles.icon}} color="primary" endIcon={<ChevronRightIcon />}>Explore Now</Button>
    </div>
  </a>
}

export default HomeBanner