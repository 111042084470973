import React from 'react'
import { observer } from "mobx-react-lite"
import { useNavigate } from "@reach/router"

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import {ReactComponent as DiscordIcon} from '../../images/discord-black.svg'



import classes from './Footer.module.scss'
import clsx from 'clsx';


const Footer = observer(({mini, className}) => {
  const navigate = useNavigate();
  const handleNav = (e) => {
    e.preventDefault()
    navigate(e.target.href)
  }
  return <footer className={clsx(classes.root, mini && classes.mini, className)}>
  <div className={classes.footerLeft}>
    <div className={classes.copyright}>© 2024 Session Loops, Inc. {mini ? "" : 'All rights reserved'}</div>
    <div className={classes.policies}>
      <a href="/policies/terms-of-service" onClick={handleNav}>{mini ? "Terms" : 'Terms of Service'}</a>
      <a href="/policies/privacy-policy" onClick={handleNav}>Privacy Policy</a>    
      <a href="mailto:contact@sessionloops.com">Contact</a>
      <a href="https://sessionloops.firstpromoter.com/"  target="_blank">Affiliates</a>    
    </div>
  </div>
  <div className={classes.footerRight}>
    <a href="https://www.facebook.com/SessionLoops" target="_blank"><FacebookIcon /></a>
    <a href="https://twitter.com/SessionLoops" target="_blank"><TwitterIcon /></a>
    <a href="https://www.youtube.com/channel/UCy-OnrvyjS9QyNwUQhd6Okw" target="_blank"><YouTubeIcon /></a>
    <a href="https://instagram.com/session_loops" target="_blank"><InstagramIcon /></a>
    <a href="https://discord.gg/REEHXucYTb" target="_blank"><DiscordIcon style={{width: 24, height: 24}}/></a>
  </div>
</footer>
})

export default Footer;