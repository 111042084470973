import React, { useEffect } from 'react'
import Layout from '../layout/Layout'
import { observer } from "mobx-react-lite"
// import Button from '@mui/material/Button'
import { useNavigate } from "@reach/router"

const NotFound = observer(() => {
  const navigate = useNavigate();
  useEffect(() => {
    const t = setTimeout(() => {
      navigate('/', {replace: true})
    }, 3000)
    return () => {
      clearTimeout(t)
    }
  }, [navigate])
  return <Layout className="not-found">
    <div style={{textAlign: 'center'}}>
      {/* <h1 style={{textAlign: 'center'}}>404</h1> */}
      <h1 style={{textAlign: 'center', marginTop: 45}}>Page not found </h1>
      {/* <Button style={{margin: '20px auto'}} onClick={() => navigate('/')}>GO HOME</Button> */}
    </div>
  </Layout>
})

export default NotFound