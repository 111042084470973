import React, {useEffect, Suspense, lazy} from 'react'
import { observer } from "mobx-react-lite"
import { Router } from "@reach/router"
import PrivateRoute from '../features/common/PrivateRoute'
import useUser from '../features/common/useUser'
import InitApp from '../components/InitApp'
import NotFound from '../features/layout/NotFound'
import Index from '../features/index'
import SubscribeModal from '../features/account/SubscribeModal'
const LivePerformance = lazy(() => import('../features/catalog/LivePerformance'));
const ProducerSounds = lazy(() => import('../features/catalog/ProducerSounds'));
const Pack = lazy(() => import('../features/pack/Pack'));
const MyLibrary = lazy(() => import('../features/my/MyLibrary'));
const Auth = lazy(() => import('../features/auth/Auth'));
const Settings = lazy(() => import('../features/account/Settings'));
const VerifyEmail = lazy(() => import('../features/account/VerifyEmail'));
const ResetPassword = lazy(() => import('../features/auth/ResetPassword'));
const Faq = lazy(() => import('../features/help/faq'));
const DrumNet = lazy(() => import('../features/welcome/DrumNet'));
// const DrumNetZh = lazy(() => import('../features/welcome/DrumNetZh'));
const Packs = lazy(() => import('../features/welcome/Packs'))
const Reviews = lazy(() => import('../features/welcome/Reviews'))
const ShortcutsAndFx = lazy(() => import('../features/help/shortcuts-and-fx'));
const Copyrights = lazy(() => import('../features/policies/Copyrights'));
const Google = lazy(() => import('../features/policies/Google'));
const PrivacyPolicy = lazy(() => import('../features/policies/PrivacyPolicy'));
const PricingTerms = lazy(() => import('../features/policies/PricingTerms'));
const AcceptableUseAndFairUsagePolicy = lazy(() => import('../features/policies/AcceptableUseAndFairUsagePolicy'));
const TermsOfService = lazy(() => import('../features/policies/TermsOfService'));
const PayPlan = lazy(() => import('../features/account/PayPlan'))
const Checkout = lazy(() => import('../features/account/Checkout'))
const Share = lazy(() => import('../features/share/Share'))
const Unsubscribe = lazy(() => import('../features/auth/Unsubscribe'))
const Pricing = lazy(() => import('../features/account/Pricing'))
const AppBuy = lazy(() => import('../features/account/AppBuy'))


// 路由
const Routes = observer(() => {
  const { user, init } = useUser()
  useEffect(() => {
    init()
  }, [init, user.auth])

  if (user.auth === 0) {
    return <InitApp />
  }

  return (
    <Suspense fallback={<div></div>}>
    <SubscribeModal />
    <Router>
      <Index path="/" />
      <LivePerformance path="/catalog/live-performance" />
      <ProducerSounds path="/catalog/producer-sounds" />
      <DrumNet path="/drumnet" />
      {/*<DrumNetZh path="/drumnet/zh" />*/}
      <Packs path="/packs" />
      <PrivateRoute as={Settings} path="/account/*" />
      <PrivateRoute as={PayPlan} path="/account/pay-plan" />
      <PrivateRoute as={Checkout} path="/account/checkout" />
      <PrivateRoute as={AppBuy} path="/account/app-buy" />
      <Pricing path="/pricing" />
      {/* <PrivateRoute as={Home} path="/" /> */}
      <Pack path="/pack/:packId" />
      <PrivateRoute as={MyLibrary} path="/my-library" />

      <ResetPassword path="/auth/reset-password" />
      <VerifyEmail path="/auth/verify-email" />
      <VerifyEmail path="/auth/change-email" />
      <Unsubscribe path="/auth/unsubscribe" />
      <Auth path="/auth/*" />

      <TermsOfService path="/policies/terms-of-service" />
      <AcceptableUseAndFairUsagePolicy path="/policies/acceptable-use-and-fair-usage-policy" />
      <Copyrights path="/policies/copyrights" />
      <PricingTerms path="/policies/pricing-terms" />
      <PrivacyPolicy path="/policies/privacy-policy" />
      <Google path="/policies/google" />

      <Faq path="/help/faq" />
      <Reviews path="/reviews" />
      <ShortcutsAndFx path="/help/shortcuts-and-fx" />

      <Share path="/s/:shareCode" />
      <Share path="/i/:shareCode" />

      <NotFound path="/404" />
      <NotFound path="*" />
    </Router>
    </Suspense>
  )
})

export default Routes
